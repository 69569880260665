import React, { useState, useEffect, useCallback } from "react";
import "./fonts.css";
import "./App.css";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import Topbar from "./Components/topbar/Topbar";
import Sidebar from "./Components/sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthContext from "./Contexts/authContext";
import { SelectedGroupProvider } from "./Contexts/SelectedGroup";
import { parseJwt, isUserAdmin, isAuthenticated } from "./utils";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const [userInfose, setUserInfose] = useState({});
  const [localStorageToken, setLocalStorageToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const login = useCallback((userInfose, token) => {
    setToken(token);
    setIsLoggedIn(true);
    setUserInfose(userInfose);
    localStorage.setItem("user", JSON.stringify({ token }));
  });

  const logout = useCallback(() => {
    setToken(null);
    setUserInfose({});
    localStorage.removeItem("user");
  });

  const router = useRoutes(routes);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("user"));
    if (isUserAdmin() && isAuthenticated()) {
      setIsLoggedIn(true);
      setToken(localStorageData.token);
    } else {
      if (location.pathname !== "/login") {
        Swal.fire({
          icon: "warning",
          title: "ورود به سیستم",
          text: "قبل از ورود به سیستم ابتدا باید لاگین کنید.",
        }).then((result) => {
          navigate("/login");
        });
      }
    }
  }, [location.pathname]);

  return (
    <>
      <Topbar setIsLoggedIn={setIsLoggedIn} />
      <div className="container">
        <SelectedGroupProvider>
          <AuthContext.Provider
            value={{
              isLoggedIn,
              token,
              userInfose,
              login,
              logout,
            }}
          >
            {isLoggedIn && <Sidebar />}
            {router}
          </AuthContext.Provider>
        </SelectedGroupProvider>
      </div>
    </>
  );
}

export default App;
