import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import config from "../../config";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { formatDate, setHeaders } from "../../utils";
import Swal from "sweetalert2";
import usePagination from "../../hooks/usePagination";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import "./Galleries.css";
import EditModal from "./EditModal";
import AttachFilesModal from "./AttachFilesModal";
import { createPortal } from "react-dom";

function Galleries() {
  const [galleryList, setGalleryList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingGalleryID, setEditingGalleryID] = useState(null);
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);

  const {
    page,
    pageSize,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    setTotalRows,
  } = usePagination(getAllGalleries);

  function handleEditClick(galleryID) {
    setEditingGalleryID(galleryID);
    setEditModalOpen(true);
  }

  function openFilesModal(galleryID) {
    setEditingGalleryID(galleryID);
    setIsFilesModalOpen(true);
  }

  function getAllGalleries() {
    const headers = setHeaders();
    axios
      .get(
        `${config.hostname}/api/v1/Gallery?pageNumber=${page}&pageSize=${pageSize}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("res data", res.data);
          setGalleryList(res.data.gallerylist);
          setTotalRows(res.data.totalcount);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function deleteGallery(galleryID) {
    Swal.fire({
      icon: "question",
      title: "آیا از حذف گالری مطمئن هستید؟",
      confirmButtonText: "بله",
      showCancelButton: true,
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .delete(config.hostname + `/api/v1/Gallery/${galleryID}`, {
            headers,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "گالری با موفقیت حذف شد.",
              });
              getAllGalleries();
            }
          })

          .catch((error) => {
            console.error("خطا در درخواست به API برای حذف گالری': ", error);
          });
      }
    });
  }

  const columns = [
    {
      field: "keyID",
      headerName: "شناسه ",
      width: 70,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 80,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => openFilesModal(params.row.keyID)}
              >
                <AddAPhotoIcon className="userListEdit" />
              </Link>
            }
            {
              <Link
                className="link"
                onClick={() => handleEditClick(params.row.keyID)}
              >
                <EditIcon className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => deleteGallery(params.row.keyID)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "mainPicPath",
      headerName: "عکس اصلی",
      width: 150,
      renderCell: (params) => (
        <img
          className="main-img"
          src={params.row.mainPicPath || "/images/default.png"}
          alt="main"
        />
      ),
    },
    {
      field: "title",
      headerName: "عنوان",
      width: 150,
    },
    {
      field: "name",
      headerName: "عنوان (به انگلیسی)",
      width: 150,
    },
    {
      field: "description",
      headerName: "توضیحات",
      width: 150,
    },
    {
      field: "eventDate",
      headerName: "تاریخ ایجاد",
      width: 110,
      renderCell: (params) => <span>{formatDate(params.row.eventDate)}</span>,
    },
    {
      field: "isActive",
      headerName: "وضعیت",
      width: 80,
      renderCell: (params) => (
        <span>{params.row.isActive ? "فعال" : "غیرفعال"}</span>
      ),
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={galleryList}
          getRowId={(row) => row.keyID}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          rowCount={totalRows}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      {isEditModalOpen && (
        <EditModal
          Idd={editingGalleryID}
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          refresh={getAllGalleries}
        />
      )}

      {isFilesModalOpen &&
        createPortal(
          <AttachFilesModal
            Idd={editingGalleryID}
            isOpen={isFilesModalOpen}
            onClose={() => setIsFilesModalOpen(false)}
            refresh={getAllGalleries}
          />,
          document.getElementById("modal")
        )}
    </>
  );
}

export default Galleries;
