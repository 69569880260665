import React, { useState, useEffect } from "react";
import { Modal, Paper, TextField, Button } from "@mui/material";
import axios from "axios";
import config from "../../config";
import jalaliMoment from "jalali-moment";
import DisplayImage from "./DisplayImage";
import FileUploader3 from "../../Components/files/FileUploader3";
import { setHeaders } from "../../utils";

function ModalAttachExcerFile({ isOpen, onClose, Idd }) {
  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const initialFileData = {
    KeyID: -1,
    FileName: "",
    FileContent: "",
    FileTitle: "",
    FileDescription: "",
    FilesAddress: "",
    FileType: "",
    isImage: false,
  };

  const initialFileList = [{ ...initialFileData }];
  const [educationFilenewList, seteducationFilenewList] = useState([]);
  const [educationFileList, setEducationFIleList] = useState(initialFileList);
  const [uploadProgress, setUploadProgress] = useState(0);
  const handleFileChange = (files) => {
    setSelectedFiles(files);
  };

  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "0",
  });

  useEffect(() => {
    setSelectedFiles([]);
    seteducationFilenewList([]);
    setEducationFIleList([]);
    setExercisesData({});
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/Exercise/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setExercisesData(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      axios
        .get(config.hostname + `/api/v1/Exercise/${Idd}/ExerciseFiles`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            const educationfilData = res.data;

            const formattedData = educationfilData.map((item) => ({
              KeyID: item.keyID,
              FileName: item.fileName,
              FileContent: item.fileContent,
              FileTitle: item.fileTitle,
              FileDescription: item.fileDescription,
              FilesAddress: item.filesAddress,
              FileType: item.fileType,
              isImage: item.isImage,
            }));
            seteducationFilenewList(formattedData);
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setExercisesData({
        name: "",
        subject1: "",
        subject2: "",
        preparation: "",
        execution: "",
      });
    }
  }, [Idd]);

  const loadFormData = () => {
    setSelectedFiles([]);
    seteducationFilenewList([]);
    setEducationFIleList([]);
    setExercisesData({});
    const headers = setHeaders();
    axios
      .get(config.hostname + `/api/v1/Exercise/${Idd}/ExerciseFiles`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          const formattedData = res.data.map((item) => ({
            KeyID: item.keyID,
            FileName: item.fileName,
            FileContent: item.fileContent,
            FileTitle: item.fileTitle,
            FileDescription: item.fileDescription,
            FilesAddress: item.filesAddress,
            FileType: item.fileType,
            isImage: item.isImage,
          }));
          seteducationFilenewList(formattedData);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  const insertFiles = async () => {
    setUploading(true);
    try {
      const filesData = [];
      const promises = selectedFiles.map(async (file) => {
        return new Promise((resolve) => {
          const fileData = new FormData();
          const reader = new FileReader();
          reader.onload = () => {
            const fileBytes = new Uint8Array(reader.result);
            fileData.fileName = file.name;
            fileData.keyID = 0;
            fileData.fileContent = Array.from(fileBytes).join(",");
            fileData.FileTitle = "title";
            fileData.FileDescription = "description";
            fileData.FilesAddress = "";
            fileData.FileType = file.name.split(".").pop();
            fileData.isImage =
              fileData.FileType.toUpperCase() === "JPG" ||
              fileData.FileType.toUpperCase() === "PNG" ||
              false;
            filesData.push(fileData);
            resolve();
          };
          reader.readAsArrayBuffer(file);
        });
      });

      await Promise.all(promises);
      const headers = setHeaders();

      axios
        .post(
          config.hostname + `/api/v1/Exercise/${Idd}/ExerciseFiles`,
          filesData,
          {
            headers,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              setUploadProgress(percentCompleted);
            },
          }
        )
        .then((res) => {
          console.log("res upload", res);
          loadFormData();
          onClose();
        });
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteFile = (e, fileData, isImage) => {
    const confirmDelete = window.confirm(
      "آیا مطمئن هستید که می‌خواهید این فایل را حذف کنید؟"
    );

    if (confirmDelete) {
      const headers = setHeaders();
      axios
        .delete(
          config.hostname +
            `/api/v1/Exercise/${Idd}/ExerciseFiles/${fileData},${isImage}`,
          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            const updatedFileList = educationFilenewList.filter(
              (item) => item.KeyID !== fileData
            );
            seteducationFilenewList(updatedFileList);
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API برای حذف فایل: ", error);
        });
    }
  };

  return (
    <Modal className="add-exercise-modal" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "افزودن فایل های تمرین" : "افزودن فایل های تمرین"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <TextField
            itemID="exercisesName"
            label="عنوان تمرین"
            value={exercisesData.name}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, name: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesSubject1"
            label="عنوان مرحله اماده سازی"
            value={exercisesData.subject1}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject1: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesPreparation"
            label="توضیحات آماده سازی"
            value={exercisesData.preparation}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                preparation: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesSubject2"
            label="عنوان مراحل اجرا"
            value={exercisesData.subject2}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject2: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesExecution"
            label="توضیحات اجرای تمرین"
            value={exercisesData.execution}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, execution: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesCategoryID"
            label="شناسه دسته‌بندی آموزش"
            value={exercisesData.exercisesCategoryID}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                exercisesCategoryID: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />
          <div>
            <h2> فایل های پیوست شده :</h2>
            <DisplayImage
              filesData={educationFilenewList}
              onDeleteFile={handleDeleteFile}
              prop="KeyID"
            ></DisplayImage>
          </div>

          <h2>لیست انتظار آپلود : </h2>
          <ul className="uploading-list">
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>

          <FileUploader3 onChange={handleFileChange} />
          {uploading && (
            <div style={{ color: "red", marginTop: "10px" }}>
              در حال آپلود فایل... لطفاً تا اتمام آپلود صفحه را نبندید.
              <p style={{ fontWeight: "bold" }}>
                درصد آپلود شده: {uploadProgress}
              </p>
            </div>
          )}
          <Button
            variant="contained"
            onClick={insertFiles}
            sx={{ marginTop: 2 }}
            style={{ marginBottom: "1rem" }}
          >
            افزودن فایل ها
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalAttachExcerFile;
