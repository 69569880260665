import { Button, FormControl, FormLabel, Modal, Paper } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import config from "../../config";
import {
  addDaysToDate,
  getDaysDifference,
  setHeaders,
} from "../../utils";
import "./OrderDetails.css";
import PersianDatePicker from "../../Components/PersianDatePicker";
import Swal from "sweetalert2";

export default function ExtensionModal({
  isOpen,
  onClose,
  refresh,
  orderDetails,
}) {
  const minDate = addDaysToDate(
    orderDetails.paymentDate,
    orderDetails.serviceDay
  );

  const [extensionDate, setExtensionDate] = useState(null);

  function closeModal() {
    onClose();
  }

  function changeDate(formatedTime) {
    const newDate = new Date(formatedTime);
    setExtensionDate(newDate.toISOString());
  }

  function editOrderExpDate() {
    const editedOrderData = {
      keyID: orderDetails.keyID,
      days: getDaysDifference(orderDetails.paymentDate, extensionDate),
    };

    Swal.fire({
      icon: "question",
      title: "تمدید سرویس",
      text: `آیا از تمدید سرویس ${orderDetails.physicianFname} ${orderDetails.physicianLname} مطمئن هستید؟`,
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .put(config.hostname + "/api/v1/Order", editedOrderData, {
            headers,
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "سرویس با موفقیت تمدید شد",
              });
              refresh();
              onClose();
            }
          })
          .catch((error) => {
            console.error("خطا در درخواست به API: ", error);
          });
      }
    });
  }

  return (
    <Modal className="order-modal" open={isOpen} onClose={closeModal}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        {orderDetails && (
          <div style={{ flex: 1, padding: "0 10px" }}>
            <h2>تمدید سفارش</h2>
            <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
            <div style={{ textAlign: "center" }}>
              <FormControl>
                <FormLabel>تمدید تا تاریخ</FormLabel>
                <PersianDatePicker
                  initialDate={minDate}
                  minDate={minDate}
                  setTime={changeDate}
                />
              </FormControl>
            </div>

            <Button
              variant="contained"
              fullWidth
              onClick={editOrderExpDate}
              sx={{ marginTop: 2 }}
            >
              تمدید
            </Button>
          </div>
        )}
      </Paper>
    </Modal>
  );
}
