import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { setHeaders } from "../../utils";
import PersianDatePicker from "../../Components/PersianDatePicker";
import Swal from "sweetalert2";

export default function EditModal({ Idd, isOpen, onClose, refresh }) {
  const initialOff = {
    title: "",
    code: "",
    discountPercent: 0,
    startDate: "",
    endDate: "",
    isActiveForAll: true,
    isActive: true,
    description: "",
  };
  const [off, setOff] = useState(initialOff);
  const [physicianIDlist, setPhysicianIDlist] = useState([]);
  const [selectedPhysicianIDs, setSelectedPhysicianIDs] = useState([]);

  useEffect(() => {
    getPhysicianList();
  }, []);

  useEffect(() => {
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(`${config.hostname}/api/v1/Discount/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setOff(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setOff(initialOff);
    }
  }, [Idd]);

  const handleChangeSelectedPhysicians = (event, option) => {
    const isSelected = selectedPhysicianIDs.some(
      (item) => item.id === option.id
    );

    isSelected
      ? setSelectedPhysicianIDs((prevSelected) =>
          prevSelected.filter((item) => item.id !== option.id)
        )
      : setSelectedPhysicianIDs((prevSelected) => [...prevSelected, option]);
  };

  function getPhysicianList() {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Physician/1,10000", { headers })
      .then((res) => {
        if (res.status === 200) {
          const physicianData = res.data.physicianModelList;

          const formattedData = physicianData.map((item) => ({
            id: item.keyID,
            fName: item.fName,
            lName: item.lName,
          }));
          setPhysicianIDlist(formattedData);

          if (Idd > 0) {
            axios
              .get(
                config.hostname + `/api/v1/Discount/GetPhysicianList?id=${Idd}`,
                {
                  headers,
                }
              )
              .then((res) => {
                res.status === 200 &&
                  setSelectedPhysicianIDs(
                    formattedData.filter(({ id }) =>
                      res.data.some((doctorID) => doctorID === id)
                    )
                  );
              })
              .catch((error) => {
                console.error("خطا در درخواست به API: ", error);
              });
          }
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function sendPhysicianIDList(offID, isEditing) {
    try {
      const headers = setHeaders();
      const newPhysicianIDList = {
        physicianIDlist: selectedPhysicianIDs.map((doctor) => doctor.id),
        FK_DiscountID: offID,
        IsActiveForAll: false,
      };

      axios
        .post(
          config.hostname + `/api/v1/Discount/PostPhysicians`,
          newPhysicianIDList,
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "تخفیف با موفقیت اضافه شد",
            });
            refresh();
            onClose();
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  }

  async function updateOff() {
    try {
      const headers = setHeaders();
      const newOff = {
        ...off,
        keyId: Idd,
      };

      axios
        .put(config.hostname + `/api/v1/Discount`, newOff, { headers })
        .then((res) => {
          if (res.status === 200) {
            if (!off.isActiveForAll) {
              sendPhysicianIDList(Idd, true);
            } else {
              Swal.fire({
                icon: "success",
                title: "تخفیف با موفقیت ویرایش شد",
              });
              refresh();
              onClose();
            }
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "کد تخفیف باید حداقل 6 کاراکتر داشته باشد",
      });
      console.error("خطا در ویرایش کاربر: ", error);
    }
  }

  async function insertOff() {
    try {
      const headers = setHeaders();
      const newOff = {
        ...off,
        keyId: 0,
      };

      axios
        .post(config.hostname + `/api/v1/Discount`, newOff, { headers })
        .then((res) => {
          if (res?.status === 200) {
            if (!off.isActiveForAll) {
              sendPhysicianIDList(res.data);
            } else {
              Swal.fire({
                icon: "success",
                title: "تخفیف با موفقیت اضافه شد",
              });
              refresh();
              onClose();
            }
          }
        })
        .catch((error) => {
          error.status === 400 &&
            Swal.fire({
              icon: "error",
              title:
                "کد تخفیف باید حداقل 6 کاراکتر داشته باشد, تاریخ شروع و پایان را مشخص کنید.",
            });
        });
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  }

  function closeModal() {
    setOff(initialOff);
    onClose();
  }

  function changeDate(formatedTime, propName) {
    const newDate = new Date(formatedTime);
    setOff((prevState) => ({
      ...prevState,
      [propName]: newDate.toISOString(),
    }));
  }

  function changeStartDate(formatedTime) {
    changeDate(formatedTime, "startDate");
  }

  function changeEndtDate(formatedTime) {
    changeDate(formatedTime, "endDate");
  }

  return (
    <Modal className="offs-modal" open={isOpen} onClose={closeModal}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش تخفیف" : "ایجاد تخفیف"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-around",
            }}
          >
            <FormControl>
              <FormLabel>وضعیت</FormLabel>
              <RadioGroup
                row
                aria-label="isActive"
                name="isActive"
                value={off.isActive ? 1 : 0}
                onChange={(e) => {
                  setOff((prevState) => ({
                    ...prevState,
                    isActive: !!+e.target.value,
                  }));
                }}
              >
                <FormControlLabel
                  style={{ marginRight: "0" }}
                  value={1}
                  control={<Radio />}
                  label="فعال"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="غیرفعال"
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel>فعال برای همه</FormLabel>
              <RadioGroup
                row
                aria-label="isActiveForAll"
                name="isActiveForAll"
                value={off.isActiveForAll ? 1 : 0}
                onChange={(e) =>
                  setOff((prevState) => ({
                    ...prevState,
                    isActiveForAll: !!+e.target.value,
                  }))
                }
              >
                <FormControlLabel
                  style={{ marginRight: "0" }}
                  value={1}
                  control={<Radio />}
                  label="بله"
                />
                <FormControlLabel value={0} control={<Radio />} label="خیر" />
              </RadioGroup>
            </FormControl>
          </div>

          {!off.isActiveForAll && (
            <FormControl style={{ width: "500px" }}>
              <InputLabel id="physicianIDlist">لیست پزشکان</InputLabel>
              <Select
                labelId="multi-select-label"
                multiple
                value={selectedPhysicianIDs}
                renderValue={(selected) =>
                  selected
                    .map((item) => `${item.fName} ${item.lName}`)
                    .join(", ")
                }
              >
                {physicianIDlist.map((option) => (
                  <MenuItem
                    onClick={(e) => handleChangeSelectedPhysicians(e, option)}
                    key={option.id}
                    value={option}
                  >
                    <Checkbox
                      checked={selectedPhysicianIDs.some(
                        (item) => item.id === option.id
                      )}
                    />
                    <ListItemText primary={`${option.fName} ${option.lName}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <div style={{ display: "flex", gap: "1rem" }}>
            <TextField
              itemID="title"
              type="text"
              label="عنوان تخفیف"
              value={off.title}
              onChange={(e) => setOff({ ...off, title: e.target.value })}
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
          </div>

          <div style={{ display: "flex", gap: "1rem" }}>
            <TextField
              itemID="discountPercent"
              type="number"
              label="تخفیف درصد (%)"
              value={off.discountPercent}
              onChange={(e) =>
                setOff({ ...off, discountPercent: +e.target.value })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />

            <TextField
              itemID="code"
              type="text"
              label="کد تخفیف"
              value={off.code}
              onChange={(e) => setOff({ ...off, code: e.target.value })}
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
          </div>

          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: "10px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <FormControl>
              <FormLabel>تاریخ شروع</FormLabel>

              <PersianDatePicker
                initialDate={off.startDate ? off.startDate : null}
                setTime={changeStartDate}
              />
            </FormControl>
            <FormControl>
              <FormLabel>تاریخ پایان</FormLabel>

              <PersianDatePicker
                initialDate={off.endDate ? off.endDate : null}
                setTime={changeEndtDate}
              />
            </FormControl>
          </div>

          <TextField
            itemID="description"
            label="توضیحات"
            value={off.description}
            onChange={(e) => setOff({ ...off, description: e.target.value })}
            multiline
            rows={6}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <Button
            variant="contained"
            fullWidth
            onClick={Idd > 0 ? updateOff : insertOff}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش تخفیف" : "ایجاد تخفیف"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
