import React, { useState, useEffect, useContext } from "react";
import { Modal, Paper, TextField, Button } from "@mui/material";
import MyTree from "./treeview";
import TextFieldwithAddIcon from "../../Components/TextFildwithAddIcon/TextFildwithAddIcon";
import { red } from "@mui/material/colors";
import axios from "axios";
import config from "../../config.js";
import ListView from "./listview";
import { SelectedGroup } from "../../Contexts/SelectedGroup";
import { setHeaders } from "../../utils.js";

function ModalGroup({ isOpen, onClose, Idd }) {
  const { selectedGroupsList } = useContext(SelectedGroup);

  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "0",
  });

  const [fetchedtdataset, setfetchedtdataset] = useState([]);

  function mapdatatofetcheddata(rawData) {
    const fetchedData = [];

    const groupByKeyID = {};

    rawData.forEach((item) => {
      if (item.fK_ParentID === null) {
        groupByKeyID[item.keyID] = {
          keyID: item.keyID,
          name: item.ctgName,
          children: [],
        };
      } else {
        const parent = groupByKeyID[item.fK_ParentID];
        if (parent) {
          parent.children.push({
            keyID: item.keyID,
            name: item.ctgName,
          });
        }
      }
    });

    Object.values(groupByKeyID).forEach((group) => {
      fetchedData.push(group);
    });
    setfetchedtdataset(fetchedData);
  }

  useEffect(() => {
    if (Idd) {
      const headers = setHeaders();

      axios
        .get(config.hostname + `/api/v1/Exercise/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 &&
            setExercisesData({
              name: res.data.name,
            });
        })
        .catch((error) => {
          console.error("خطا در درخواست1 به API: ", error);
        });

      axios
        .get(config.hostname + "/api/v1/ExerciseCtg", { headers })
        .then((res) => {
          res.status === 200 && mapdatatofetcheddata(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    }
  }, [Idd]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          height: 500,
          padding: 2,
        }}
      >
        <div
          style={{
            width: "300px",
            height: "100%",
            marginLeft: "10px",
            backgroundColor: "white",
          }}
        >
          <div> گروه ها</div>
          <MyTree fetchedData={fetchedtdataset} />
        </div>
        <div
          style={{
            flex: "1",
            padding: "0 10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2>{Idd > 0 ? "دسته بندی تمرین" : "دسته بندی تمرین"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <TextField
            itemID="exercisesName"
            label="عنوان تمرین"
            value={exercisesData.name}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <ListView idd={Idd} />
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalGroup;
