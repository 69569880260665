import React, { useState, useEffect } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import gregorian from "react-date-object/calendars/gregorian";

export default function PersianDatePicker({
  setTime,
  initialDate,
  minDate,
  isFree,
}) {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (initialDate) {
      const parsedDate = new Date(initialDate);
      setSelectedDate(parsedDate);
    }
  }, [initialDate]);

  useEffect(() => {
    const date =
      selectedDate &&
      new DateObject({ calendar: gregorian, date: selectedDate });

    selectedDate !== initialDate && setTime(date);
  }, [selectedDate]);

  return (
    <Calendar
      style={{ marginTop: "10px" }}
      monthYearSeparator="|"
      calendar={persian}
      locale={persian_fa}
      value={selectedDate}
      onChange={setSelectedDate}
      minDate={isFree ? null : minDate || today}
      format="YYYY/MM/DD"
    />
  );
}
