import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import config from "../../config";
import { addDaysToDate, formatDate, setHeaders } from "../../utils";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrderDetails from "./OrderDetails";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ExtensionModal from "./ExtensionModal";
import usePagination from "../../hooks/usePagination";

function Orders() {
  const [orderList, setOrderList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [singleOrderID, setSingleOrderID] = useState(null);
  const [extensionOrderDetails, setExtensionOrderDetails] = useState(null);

  const {
    page,
    pageSize,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    setTotalRows,
  } = usePagination(getAllOrders);

  function showOrderDetails(orderID) {
    setSingleOrderID(orderID);
    setEditModalOpen(true);
  }

  function getAllOrders() {
    const headers = setHeaders();
    axios
      .get(`${config.hostname}/api/v1/Order/${page},${pageSize}`, { headers })
      .then((res) => {
        if (res.status === 200) {
          setOrderList(res.data.ordermodels);
          setTotalRows(res.data.totalcount);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function deleteOrder(orderId) {
    Swal.fire({
      icon: "question",
      title: "آیا از حذف سفارش مطمئن هستید؟",
      confirmButtonText: "بله",
      showCancelButton: true,
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .delete(config.hostname + `/api/v1/Order/${orderId}`, {
            headers,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "سفارش با موفقیت حذف شد.",
              });
              getAllOrders();
            }
          })

          .catch((error) => {
            console.error("خطا در درخواست به API برای حذف سفارش: ", error);
          });
      }
    });
  }

  const columns = [
    {
      field: "keyID",
      headerName: "شناسه ",
      width: 70,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 80,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => setExtensionOrderDetails(params.row)}
              >
                <MoreTimeIcon className="userListEdit" />
              </Link>
            }

            {
              <Link
                className="link"
                onClick={() => showOrderDetails(params.row.keyID)}
              >
                <VisibilityIcon className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => deleteOrder(params.row.keyID)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "physicianFname",
      headerName: "نام سفارش دهنده",
      width: 150,
    },
    {
      field: "physicianLname",
      headerName: "نام خانوادگی سفارش دهنده",
      width: 180,
    },
    {
      field: "serviceName",
      headerName: "نام سرویس",
      width: 150,
    },
    {
      field: "serviceDay",
      headerName: "مدت دوره",
      width: 100,
    },
    {
      field: "paymentDate",
      headerName: "تاریخ شروع",
      width: 100,
      renderCell: (params) => <span>{formatDate(params.row.paymentDate)}</span>,
    },
    {
      field: "expDate",
      headerName: "تاریخ پایان",
      width: 250,
      renderCell: (params) => (
        <span>
          {formatDate(
            addDaysToDate(params.row.paymentDate, params.row.serviceDay)
          )}
        </span>
      ),
    },
    {
      field: "isActive",
      headerName: "وضعیت",
      width: 80,
      renderCell: (params) => (
        <span>{params.row.isActive ? "فعال" : "غیر فعال"}</span>
      ),
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <DataGrid
          rows={orderList}
          getRowId={(row) => row.keyID}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          rowCount={totalRows}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      {isEditModalOpen && (
        <OrderDetails
          Idd={singleOrderID}
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          refresh={getAllOrders}
        />
      )}

      {extensionOrderDetails && (
        <ExtensionModal
          isOpen={extensionOrderDetails}
          onClose={() => setExtensionOrderDetails(null)}
          refresh={getAllOrders}
          orderDetails={extensionOrderDetails}
          setOrderDetails={setExtensionOrderDetails}
        />
      )}
    </>
  );
}

export default Orders;
