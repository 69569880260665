import { useEffect, useRef, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";
import ReactCrop, {
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import { setCanvasPreview } from "../../utils";
import "./SingleImageUploader.css";

const MIN_WIDTH = 100;
const MIN_HEIGHT = 100;

export default function SingleImageUploader({
  onImageUpload,
  title,
  activeImage,
  removeSliderImage,
  hasCrop = 16 / 9,
}) {
  const ASPECT_RATIO = hasCrop;

  console.log("aspect", ASPECT_RATIO);

  const [isDragging, setIsDragging] = useState(false);

  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [isCropSubmitted, setIsCropSubmitted] = useState(false);

  const [hasImage, setHasImage] = useState(false);

  let imageRef = useRef();
  let previewCanvasRef = useRef();
  console.log("imageRef", imageRef);

  useEffect(() => {
    console.log("imgSrc", imgSrc);
  }, [imgSrc]);

  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: MIN_WIDTH,
        height: MIN_HEIGHT,
      },
      ASPECT_RATIO,
      width,
      height
    );

    setCrop(crop);
  }

  async function handleSingleImageUpload(file) {
    const reader = new FileReader();

    console.log("single file when onload", file);

    reader.onload = () => {
      const imageUrl = reader.result?.toString() || "";
      setImgSrc(imageUrl);
    };

    reader.readAsDataURL(file);
  }

  function handleDragOver(e) {
    e.preventDefault();
    setIsDragging(true);
  }
  function handleDragLeave() {
    setIsDragging(false);
  }
  function handleDrop(e) {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];

    if (file && file.type.startsWith("image/")) {
      handleSingleImageUpload(file);
    }
  }

  function handleFileChange(e) {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      handleSingleImageUpload(file);
    }
  }

  useEffect(() => {
    crop && cropImage();
  }, [crop]);

  function cropImage() {
    setCanvasPreview(
      imageRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height),
      setHasImage
    );
  }

  function submitCrop(e) {
    e.preventDefault();

    previewCanvasRef.current.toBlob(
      (blob) => {
        console.log("previewCanvasRef", previewCanvasRef);
        if (blob) {
          onImageUpload(blob);
          setIsCropSubmitted(true);
        }
      },
      "image/webp" // مشخص کردن فرمت خروجی
    );
  }

  return (
    <div>
      {activeImage ? (
        <div className="image-wrapper">
          <img src={activeImage.filesAddress} className="image" />
          <button
            className="delete-button"
            onClick={(e) =>
              removeSliderImage(e, activeImage.keyID, activeImage.isImage, true)
            }
          >
            <TiDelete className="icon" />
          </button>
        </div>
      ) : (
        <>
          {!isCropSubmitted && (
            <div
              className={`dropzone ${isDragging ? "dragging" : ""} ${
                imgSrc ? "has-image" : ""
              }`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {imgSrc ? (
                <div>
                  <ReactCrop
                    crop={crop}
                    keepSelection
                    onChange={(pixelCrop) => {
                      setIsCropSubmitted(false);
                      setCrop(pixelCrop);
                    }}
                    aspect={ASPECT_RATIO}
                    minWidth={MIN_WIDTH}
                    minHeight={MIN_HEIGHT}
                  >
                    <img
                      ref={imageRef}
                      src={imgSrc}
                      onLoad={onImageLoad}
                      className="crop-image"
                    />
                  </ReactCrop>
                  <button
                    className="crop-cancel-button"
                    onClick={(e) => {
                      e.preventDefault();
                      setImgSrc("");
                      setHasImage(false);
                    }}
                  >
                    <TiDelete className="icon" />
                  </button>
                </div>
              ) : (
                <div className="upload-instructions">
                  <span>
                    <IoCloudUploadOutline className="upload-icon" /> کلیک کنید
                    یا <span className="bold-text">{title}</span> را اینجا بکشید
                    و رها کنید
                  </span>
                  <input
                    className="file-input"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
              )}
            </div>
          )}

          <div className="preview">
            {hasImage && <h4>عکس اصلی</h4>}
            <canvas
              className={`preview-canvas ${hasImage ? "block" : "hidden"}`}
              ref={previewCanvasRef}
            />
          </div>
          {hasImage && (
            <button
              disabled={isCropSubmitted}
              className={`submit-button ${isCropSubmitted ? "disabled" : ""}`}
              onClick={submitCrop}
            >
              {isCropSubmitted ? "ثبت شد" : "ثبت ناحیه"}
            </button>
          )}
        </>
      )}
    </div>
  );
}
