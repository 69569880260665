import { Modal, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { setHeaders } from "../../utils";

export default function EduModal({ Idd, isOpen, onClose, doctorID }) {
  const initialEducation = {
    title: "",
    content: "",
  };
  const [education, setEducation] = useState(initialEducation);
  const [doctorFullName, setDoctorFullName] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (Idd) {
      const headers = setHeaders();
      axios
        .get(`${config.hostname}/api/v1/Education/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setEducation(res.data);
          console.log("Education", res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      axios
        .get(
          `${config.hostname}/api/v1/Physician/Getbyguid?userid=${doctorID}`,
          {
            headers,
          }
        )
        .then((res) => {
          res.status === 200 &&
            setDoctorFullName(`${res.data.fName} ${res.data.lName}`);
          console.log("Physician", res.data);
        })
        .catch((err) => console.log("err", err));

      axios
        .get(`${config.hostname}/api/v1/Education/${Idd}/EducationFiles`, {
          headers,
        })
        .then((res) => {
          res.status === 200 && setFiles(res.data.result);
          console.log("EducationFiles", res.data.result);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    }
  }, [Idd]);

  function closeModal() {
    setEducation(initialEducation);
    setDoctorFullName("");
    setFiles([]);
    onClose();
  }

  return (
    <Modal
      className="offs-modal doctor-files-modal"
      open={isOpen}
      onClose={closeModal}
    >
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>جزییات آموزش</h2>
          <h4>ساخته شده توسط: {doctorFullName}</h4>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <div>
            <p>
              <span>عنوان آموزش: </span>
              {education.title}
            </p>
            <p>
              <span>توضیحات آموزش: </span>
              {education.content}
            </p>
          </div>

          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <h4>فایل های آموزش</h4>
          <div className="files education-files">
            {files.map((file, index) => (
              <div className="file education-file">
                <a
                  href={file.filesAddress}
                  rel="noreferrer"
                  target="_blank"
                  key={index}
                >
                  {file.fileType === "pdf" ? (
                    <img src="images/images.jfif" alt={`file ${index}`} />
                  ) : file.fileType === "xlsx" ? (
                    <img src="images/excel logo.png" alt={`file ${index}`} />
                  ) : (
                    <img src="images/file logo.png" alt={`file ${index}`} />
                  )}
                  <span style={{ fontSize: ".8rem" }}>{file.fileName}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </Paper>
    </Modal>
  );
}
