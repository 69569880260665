import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import "./UserList.css";
import { Button } from "@mui/material";
import ModalUser from "./modal-user";
import config from "../../config";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { setHeaders } from "../../utils";

function UsersList() {
  const [userDatas, setUserDatas] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);

  useEffect(() => {
    refreshlist();
  }, []);

  function refreshlist() {
    const headers = setHeaders();

    axios
      .get(config.hostname + "/api/v1/User", { headers })
      .then((res) => {
        if (res.status === 200) {
          const formattedData = res.data.map((item) => ({
            id: item.userid,
            username: item.username,
            email: item.email,
            phoneNumber: item.phoneNumber,
          }));
          setUserDatas(formattedData);
        }
      })

      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  const handleafterEdit = () => {
    setEditModalOpen(false);
    refreshlist();
  };

  const handleEditClick = (userId) => {
    setEditingUserId(userId);

    setEditModalOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "شناسه",
      width: 300,
      display: "none",
      hide: true,
    },
    {
      field: "action",
      headerName: "عملیات رمز",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            <Link
              className="link"
              onClick={() => handleEditClick(params.row.id)}
            >
              <EditIcon className="userListEdit" />
            </Link>
          </>
        );
      },
    },
    {
      field: "username",
      headerName: "نام کاربری",
      width: 200,
    },
    {
      field: "email",
      headerName: "ایمیل",
      width: 200,
      hide: true,
    },
    {
      field: "phoneNumber",
      headerName: "شماره تماس",
      width: 120,
      hide: true,
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={userDatas}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      {isEditModalOpen && (
        <ModalUser
          isOpen={isEditModalOpen}
          onClose={() => handleafterEdit()}
          Idd={editingUserId}
        />
      )}
    </>
  );
}

export default UsersList;
