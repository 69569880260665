import React, { useState, useEffect } from "react";
import { Modal, Paper, TextField, Button } from "@mui/material";
import axios from "axios";
import config from "../../config";
import jalaliMoment from "jalali-moment";
import { setHeaders } from "../../utils";
import Swal from "sweetalert2";

function ModalExcer({ isOpen, onClose, Idd, refresh }) {
  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "0",
  });

  useEffect(() => {
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/Exercise/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setExercisesData(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setExercisesData({
        name: "",
        subject1: "",
        subject2: "",
        preparation: "",
        execution: "",
      });
    }
  }, [Idd]);

  const updateUser = async () => {
    try {
      const headers = setHeaders();

      axios
        .put(
          config.hostname + `/api/v1/Exercise`,
          {
            keyID: Idd,
            name: exercisesData.name,
            subject1: "نحوه اجرای تمرین",
            subject2: "نکات تمرین",
            preparation: exercisesData.preparation,
            execution: exercisesData.execution,
            otherDescription: exercisesData.execution,
            exercisesCategoryID: -1,
          },
          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            refresh();
            Swal.fire({
              icon: "success",
              title: "تمرین با موفقیت ویرایش شد",
            });
          }
        });

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const insertuser = async () => {
    try {
      const headers = setHeaders();

      axios
        .post(
          config.hostname + `/api/v1/Exercise`,
          {
            keyID: -1,
            name: exercisesData.name,
            subject1: "نحوه اجرای تمرین",
            subject2: "نکات تمرین",
            preparation: exercisesData.preparation,
            execution: exercisesData.execution,
            otherDescription: exercisesData.execution,
            exercisesCategoryID: -1,
          },
          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            refresh();
            Swal.fire({
              icon: "success",
              title: "تمرین با موفقیت اضافه شد",
            });
          }
        });

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش تمرین" : "ایجاد تمرین"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <TextField
            itemID="exercisesName"
            label="عنوان تمرین"
            value={exercisesData.name}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, name: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesSubject1"
            label="عنوان نحوه اجرای تمرین"
            value={exercisesData.subject1}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject1: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesPreparation"
            label="نحوه اجرای تمرین"
            value={exercisesData.preparation}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                preparation: e.target.value,
              })
            }
            multiline
            rows={6}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesSubject2"
            label="عنوان نکات تمرین"
            value={exercisesData.subject2}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject2: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesExecution"
            label="نکات تمرین"
            value={exercisesData.execution}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, execution: e.target.value })
            }
            multiline // اضافه کردن prop multiline
            rows={6} // تنظیم تعداد ردیف‌ها
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <TextField
            itemID="exercisesCategoryID"
            label="شناسه دسته‌بندی آموزش"
            value={exercisesData.exercisesCategoryID}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                exercisesCategoryID: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <Button
            variant="contained"
            onClick={Idd > 0 ? updateUser : insertuser}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش تمرین" : "ایجاد تمرین"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalExcer;
