import { Button, Modal, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { formatDate, formatDays, setHeaders } from "../../utils";
import "./OrderDetails.css";

export default function EditModal({ Idd, isOpen, onClose, refresh }) {
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/Order/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setOrder(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    }
  }, [Idd]);

  function closeModal() {
    setOrder(null);
    onClose();
  }

  return (
    <Modal className="order-modal" open={isOpen} onClose={closeModal}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        {order && (
          <div style={{ flex: 1, padding: "0 10px" }}>
            <h2>جزییات سفارش</h2>
            <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

            <div className="order-rows">
              <div className="cell">
                <span>نام سفارش دهنده: </span>
                <span>{order.physicianFname}</span>
              </div>

              <div className="cell">
                <span>نام خانوادگی سفارش دهنده: </span>
                <span>{order.physicianLname}</span>
              </div>
            </div>

            <div className="order-rows">
              <div className="cell">
                <span>نام اشتراک: </span>
                <span>{order.serviceName}</span>
              </div>
              <div className="cell">
                <span>مدت دوره اشتراک: </span>
                <span>{formatDays(order.serviceDay)}</span>
              </div>
            </div>

            <div className="order-rows">
              <div className="cell">
                <span>کد تخفیف: </span>
                <span>{order.discountCode}</span>
              </div>

              <div className="cell">
                <span>درصد تخفیف: </span>
                <span>{order.discountPercent}%</span>
              </div>
            </div>

            <div className="order-rows">
              <div className="cell">
                <span>قیمت اشتراک: </span>
                {order.totalPrice ? (
                  <span>{order.serviceEndPrice.toLocaleString()} تومان</span>
                ) : (
                  <span>رایگان</span>
                )}
              </div>

              <div className="cell">
                <span>مبلغ پرداختی: </span>

                {order.paymentPrice ? (
                  <span>{order.paymentPrice.toLocaleString()} تومان</span>
                ) : (
                  <span>رایگان</span>
                )}
              </div>
            </div>

            <div className="order-rows">
              <div className="cell">
                <span>تاریخ ثبت سفارش: </span>
                <span>{formatDate(order.orderDate)}</span>
              </div>

              <div className="cell">
                <span>تاریخ پرداخت: </span>
                <span>{formatDate(order.paymentDate)}</span>
              </div>
            </div>

            <div className="order-rows">
              <div className="cell">
                <span>کد پیگیری: </span>
                <span>{order.refId}</span>
              </div>

              <div className="cell">
                <span>وضعیت: </span>
                <span>{order.isActive ? "فعال" : "غیرفعال"}</span>
              </div>
            </div>

            <Button
              variant="contained"
              fullWidth
              onClick={closeModal}
              sx={{ marginTop: 2 }}
            >
              بستن
            </Button>
          </div>
        )}
      </Paper>
    </Modal>
  );
}
