import React from "react";
import "./Home.css";
import TransactionBox from "../../Components/transactionBox/TransactionBox";

function Home() {
  return (
    <>
      <div className="home">
        <div className="transaction-boxes">
          <TransactionBox title="پرداختی های جدید" value="8" />
          <TransactionBox title="ثبت نام های جدید" value="10" />
          <TransactionBox title="پیام های خوانده نشده" value="2" />
          <TransactionBox title="ارسال پیام" value="0" />
        </div>
      </div>
    </>
  );
}

export default Home;
