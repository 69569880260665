import { useEffect, useState } from "react";

export default function usePagination(refreshlist) {
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    refreshlist();
  }, [page, pageSize]);

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return {
    page,
    pageSize,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    setTotalRows,
  };
}
