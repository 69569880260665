import React from "react";
import Button from "@mui/material/Button";
import "./Displayimages.css";
import { TiDelete } from "react-icons/ti";
const DisplayImages = ({ filesData, onDeleteFile, prop }) => {
  console.log("files", filesData);
  return (
    <div>
      <div id="imagesContainer">
        {filesData.map((fileData, index) => (
          <div key={index} className="imageContainer">
            <div className="imageWrapper">
              {onDeleteFile && (
                <button
                  onClick={(e) =>
                    onDeleteFile(e, fileData[prop], fileData.isImage)
                  }
                  className="remove-btn"
                >
                  <TiDelete className="icon" />
                </button>
              )}
              <a
                href={fileData.FilesAddress}
                rel="noreferrer"
                target="_blank"
                key={index}
              >
                {["jpg", "jpeg", "png", "gif", "webp"].includes(
                  fileData.FileType
                ) ? (
                  <img src={fileData.FilesAddress} alt={`file ${index}`} />
                ) : [
                    "mp4",
                    "avi",
                    "mkv",
                    "wmv",
                    "webm",
                    "mpeg",
                    "mpg",
                  ].includes(fileData.FileType) ? (
                  <video controls>
                    <source src={fileData.FilesAddress} />
                  </video>
                ) : fileData.FileType === "pdf" ? (
                  <img src="images/images.jfif" alt={`file ${index}`} />
                ) : fileData.FileType === "xlsx" ? (
                  <img src="images/excel logo.png" alt={`file ${index}`} />
                ) : (
                  <img src="images/file logo.png" alt={`file ${index}`} />
                )}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisplayImages;
