import React, { useState, useEffect } from "react";
import { Modal, Paper, TextField } from "@mui/material";
import axios from "axios";
import config from "../../config";
import TextFieldwithAddIcon from "../../Components/TextFildwithAddIcon/TextFildwithAddIcon";
import { setHeaders } from "../../utils";

function Modalsetparameters({ isOpen, onClose, Idd }) {
  const [paremeterLink, setparemeterLink] = useState("");
  const [parameters, setParameters] = useState(null);

  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "0",
  });

  useEffect(() => {
    setparemeterLink("");
    if (Idd > 0) {
      setparemeterLink(
        `${config.hostname}/api/v1/Exercise/${Idd}/ExerciseParameters`
      );
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/Exercise/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setExercisesData(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setExercisesData({
        name: "",
        subject1: "",
        subject2: "",
        preparation: "",
        execution: "",
      });
    }
  }, [Idd]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>
            {Idd > 0 ? "تعیین پارامتر های تمرین" : "تعیین پارامتر های تمرین"}
          </h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <TextField
            itemID="exercisesName"
            label="عنوان تمرین"
            value={exercisesData.name}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, name: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesSubject1"
            label="عنوان مرحله اماده سازی"
            value={exercisesData.subject1}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject1: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesPreparation"
            label="توضیحات آماده سازی"
            value={exercisesData.preparation}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                preparation: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesSubject2"
            label="عنوان مراحل اجرا"
            value={exercisesData.subject2}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, subject2: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesExecution"
            label="توضیحات اجرای تمرین"
            value={exercisesData.execution}
            onChange={(e) =>
              setExercisesData({ ...exercisesData, execution: e.target.value })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            itemID="exercisesCategoryID"
            label="شناسه دسته‌بندی آموزش"
            value={exercisesData.exercisesCategoryID}
            onChange={(e) =>
              setExercisesData({
                ...exercisesData,
                exercisesCategoryID: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextFieldwithAddIcon
            setParameters={setParameters}
            link={paremeterLink}
            keyID={Idd}
          />
        </div>
      </Paper>
    </Modal>
  );
}

export default Modalsetparameters;
