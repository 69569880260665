import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import config from "../../config";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { setHeaders } from "../../utils";
import Swal from "sweetalert2";

import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AccessModal from "./AccessModal";

function Accesses() {
  const [adminList, setAdminList] = useState([]);
  const [adminID, setAdminID] = useState(null);
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);

  useEffect(() => {
    getAllAdmins();
  }, []);

  function editAdminInfo(adminInfo) {
    Swal.fire({
      title: `${adminInfo ? "ویرایش" : "ایجاد"} ادمین`,
      html: `
            <input type="text" id="username" class="swal2-input" placeholder="نام ادمین" value=${
              adminInfo?.username || ""
            }>
            <input type="text" id="password" class="swal2-input" placeholder="رمز عبور ادمین" value=${
              adminInfo?.password || ""
            }>
             <input type="text" id="confirmPassword" class="swal2-input" placeholder="تکرار رمز عبور ادمین" value=${
               adminInfo?.confirmPassword || ""
             }>
          `,
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: "لغو",
      confirmButtonText: "ثبت",
      preConfirm: () => {
        const userName = document.getElementById("username").value;
        const password = document.getElementById("password").value;
        const confirmPassword =
          document.getElementById("confirmPassword").value;

        if (!userName || !password || !confirmPassword) {
          Swal.showValidationMessage("هر 3 فیلد را پر کنید!");
          return false;
        } else if (password !== confirmPassword) {
          Swal.showValidationMessage(
            "رمز عبور و تکرار رمز عبور با هم تطابق ندارند!"
          );
        }

        return { userName, password, confirmPassword };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { userName, password, confirmPassword } = result.value;
        const headers = setHeaders();
        const newAdmin = {
          userName,
          password,
          confirmPassword,
          role: "mainadmin",
          personKeyID: 0,
          personFullName: userName,
        };

        axios
          .post(`${config.hostname}/api/v1/User/Register`, newAdmin, {
            headers,
          })
          .then((response) => {
            if (response.status === 200) {
              getAllAdmins();
              Swal.fire({
                icon: "success",
                title: "ادمین با موفقیت ایجاد شد",
              });
            }
          })
          .catch((error) => {
            console.error("خطا در درخواست به API: ", error);
          });
      }
    });
  }

  function openAccessModal(userID) {
    setAdminID(userID);
    setIsAccessModalOpen(true);
  }

  function getAllAdmins() {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/User/GetMainAdmins", { headers })
      .then((res) => {
        if (res.status === 200) {
          setAdminList(res.data);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function deleteAdmin(offID) {
    Swal.fire({
      icon: "question",
      title: "آیا از حذف ادمین مطمئن هستید؟",
      confirmButtonText: "بله",
      showCancelButton: true,
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .delete(config.hostname + `/api/v1/Discount/${offID}`, {
            headers,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "ادمین با موفقیت حذف شد.",
              });
              getAllAdmins();
            }
          })
          .catch((error) => {
            console.error("خطا در درخواست به API برای حذف ادمین: ", error);
          });
      }
    });
  }

  const columns = [
    {
      field: "userid",
      headerName: "شناسه",
      width: 150,
    },
    {
      field: "username",
      headerName: "نام ادمین",
      width: 150,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 80,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {/* {
              <Link
                className="link"
                onClick={() =>
                  editAdminInfo({ username: "amin", password: "12341234A" })
                }
              >
                <EditIcon className="userListEdit" />
              </Link>
            } */}
            {
              <Link
                className="link"
                onClick={() => openAccessModal(params.row.userid)}
              >
                <VpnKeyIcon className="userListEdit" />
              </Link>
            }
            {/* {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => deleteOff(params.row.keyId)}
                />
              </Link>
            } */}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => editAdminInfo()} variant="contained">
            افزودن ادمین
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={adminList}
          getRowId={(row) => row.userid}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      {isAccessModalOpen && (
        <AccessModal
          Idd={adminID}
          isOpen={isAccessModalOpen}
          onClose={() => setIsAccessModalOpen(false)}
        />
      )}
    </>
  );
}

export default Accesses;
