import React, { useState, useEffect } from "react";
import { TextField, Button, IconButton, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import MuiRTL from "../MuiRTL/MuiRTL";

import "./TextFildwithAddIcon.css";
import axios from "axios";
import config from "../../config";
import { setHeaders } from "../../utils";

const TextFieldwithAddIcon = ({ setParameters, link, keyID }) => {
  const [commands, setCommands] = useState([]);
  const [parametersIncluds, setParametersIncluds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");

  const handleTitleChange = (index, newTitle) => {
    const updatedCommands = [...commands];
    updatedCommands[index].paramName = newTitle;
    setCommands(updatedCommands);
  };

  const handleValueChange = (index, newValue) => {
    const updatedCommands = [...commands];
    updatedCommands[index].paramValue = newValue;
    setCommands(updatedCommands);
  };

  // در این بخش مقادیر پارامترهایی که وجود دارند میتونه ادیت بشه
  const handleParamNameChange = (keyID, newName) => {
    const updatedParametersIncluds = parametersIncluds.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramName: newName };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };

  const handleParamValueChange = (keyID, newValue) => {
    const updatedParametersIncluds = parametersIncluds.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramValue: newValue };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };
  // =============

  const handleAddCommand = (newTitle, newValue, keyID) => {
    const newCommand = {
      keyID: 322,
      paramName: newTitle,
      paramValue: newValue,
      paramTyp: "string",
      isDefault: true,
      links: [
        {
          href: `https://api.bettermove.ir/api/v1/Exercise/${keyID}/ExerciseParameters`,
          method: "Put",
          rel: "Update",
        },
      ],
    };
    setCommands([...commands, newCommand]);
    setTitle("");
    setValue("");
  };

  const handleRemoveCommand = (index) => {
    const updatedCommands = commands.filter((_, i) => i !== index);
    setCommands(updatedCommands);
  };

  const handleRemoveParametersIncludes = (index) => {
    const updatedParametersIncluds = parametersIncluds.filter(
      (i) => i.keyID !== index
    );
    setParametersIncluds(updatedParametersIncluds);
  };

  // ارسال پارامترهای جدید و ادیت شده

  //-------===============

  const handleSubmit = (keyID) => {
    setParameters(commands);
    const newParameters = [...parametersIncluds, ...commands];
    const dataToSend = newParameters.map((row) => ({
      keyID: 0,
      paramName: row.paramName,
      paramValue: row.paramValue,
      paramType: "string",
      isDefault: true,
    }));

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const headers = setHeaders();
      axios
        .post(
          `${config.hostname}/api/v1/Exercise/${keyID}/ExerciseParameters`,
          dataToSend,
          { headers }
        )
        .then((response) => {
          if (!response.status === 200) {
            throw new Error("Network response was not ok");
          }
          if (response.status === 200) {
            return "OK";
          }
        })
        .catch((error) => {
          console.error("Error sending data:", error);
        });
    }
  };

  // دریافت پرامترها از api
  const getApiData = (link) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const headers = setHeaders();

      setIsLoading(true);
      axios
        .get(link, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setParametersIncluds(res.data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setParametersIncluds([]);
    if (link) {
      getApiData(link);
    }
  }, [link]);

  useEffect(() => {
    if (parametersIncluds.length > 0) {
      setDataReceived(true);
    }
  }, [parametersIncluds]);

  return (
    <div>
      <MuiRTL>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {commands.map((command, index) => (
              <Paper
                key={index}
                elevation={3}
                style={{
                  padding: "1rem",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      label="عنوان"
                      variant="filled"
                      fullWidth
                      value={command.paramTitle}
                      onChange={(e) => handleTitleChange(index, e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="مقدار"
                      variant="filled"
                      fullWidth
                      value={command.paramValue}
                      onChange={(e) => handleValueChange(index, e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => handleRemoveCommand(index)}
                      color="secondary"
                    >
                      <CancelIcon style={{ color: "rgba(176,56,60,255)" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}

            {parametersIncluds && parametersIncluds.length ? (
              // اگه پارامتری وجود داشته باشه
              parametersIncluds.map((p) => (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ padding: "1rem" }}
                >
                  <Grid item xs={6}>
                    <TextField
                      style={{ fontFamily: "IRANSans" }}
                      label="عنوان"
                      variant="filled"
                      fullWidth
                      value={p.paramName}
                      onChange={(e) =>
                        handleParamNameChange(p.keyID, e.target.value)
                      }
                      // onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      style={{ fontFamily: "IRANSans" }}
                      label="مقدار"
                      variant="filled"
                      fullWidth
                      value={p.paramValue}
                      onChange={(e) =>
                        handleParamValueChange(p.keyID, e.target.value)
                      }
                      // onChange={(e) => setValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {/* <IconButton onClick={handleAddCommand} color="primary">
                  <AddIcon fontSize='large'/>
                </IconButton> */}
                    <IconButton
                      onClick={() => handleRemoveParametersIncludes(p.keyID)}
                      color="secondary"
                    >
                      <CancelIcon style={{ color: "rgba(176,56,60,255)" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))
            ) : (
              // اگه پارامتری وجود نداشته باشه

              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ padding: "1rem" }}
              >
                <Grid item xs={6}>
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    label="عنوان"
                    variant="filled"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    label="مقدار"
                    variant="filled"
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={handleAddCommand} color="primary">
                    <AddIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            <div
              style={{
                display: "flex",
                margin: "1rem",
                flexDirection: "column",
              }}
            >
              <Button
                variant="outlined"
                color="success"
                onClick={() => handleAddCommand(title, value, keyID)}
                style={{ margin: "0.5rem", borderRadius: "0px" }}
              >
                <AddIcon fontSize="large" />
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(keyID)}
                style={{ borderRadius: "0px" }}
              >
                <p style={{ color: "wheat" }}>ثبت</p>
              </Button>
            </div>
          </>
        )}
      </MuiRTL>
    </div>
  );
};

export default TextFieldwithAddIcon;
