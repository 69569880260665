import { Button, Modal, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { setHeaders } from "../../utils";
import Swal from "sweetalert2";
import SingleImageUploader from "../../Components/SingleImageUploader/SingleImageUploader";
import "react-image-crop/dist/ReactCrop.css";
import "./Files.css";
import MultiImageUploader from "../../Components/MultiImageUploader/MultiImageUploader";
import CircularProgressBar from "../../Components/CircularProgressBar/CircularProgressBar";
import { TiDelete } from "react-icons/ti";

export default function AttachFilesModal({ Idd, isOpen, onClose, refresh }) {
  const [sliderImages, setSliderImages] = useState([]);
  const [newSliderImages, setNewSliderImages] = useState([]);
  const [sliderImagesProgress, setSliderImagesProgress] = useState(0);
  const [mainImage, setMainImage] = useState(null);
  const [mainImageProgress, setMainImageProgress] = useState(0);

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    console.log("idd", Idd);
    const headers = setHeaders();
    axios
      .get(
        `${config.hostname}/api/v1/Gallery/${Idd}/GalleryFiles/GetByGallery`,
        { headers }
      )
      .then((res) => {
        res.status === 200 && setSliderImages(res.data);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }, []);

  useEffect(() => {
    console.log("slider images", sliderImages);
  }, [sliderImages]);

  function uploadFiles() {
    setIsUploading(true);
    const headers = setHeaders();
    let uploadMainImage;
    if (mainImage) {
      uploadMainImage = axios.post(
        `${config.hostname}/api/v1/Gallery/${Idd}/GalleryFiles`,
        [mainImage],
        {
          headers,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.floor((loaded * 100) / total);
            setMainImageProgress(percentCompleted);
          },
        }
      );
    } else {
      setMainImageProgress(100);
      uploadMainImage = Promise.resolve();
    }

    let uploadSliderImages;

    if (newSliderImages.length > 0) {
      uploadSliderImages = axios.post(
        `${config.hostname}/api/v1/Gallery/${Idd}/GalleryFiles`,
        newSliderImages.map(({ preview, ...rest }) => rest),
        {
          headers,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.floor((loaded * 100) / total);
            setSliderImagesProgress(percentCompleted);
          },
        }
      );
    } else {
      setSliderImagesProgress(100);
      uploadSliderImages = Promise.resolve();
    }

    Promise.all([uploadMainImage, uploadSliderImages])
      .then(() => {
        setIsUploading(false);
        Swal.fire({
          icon: "success",
          text: "فایل ها با موفقیت آپلود شدند",
        });
        refresh();
        onClose();
      })
      .catch(() => {
        setIsUploading(false);
      });
  }

  function setImageprops(file, isPostOnce) {
    return new Promise((resolve) => {
      const fileData = {};
      const reader = new FileReader();
      reader.onload = () => {
        const fileType = file.type.split("/").pop();
        const fileBytes = new Uint8Array(reader.result);
        fileData.fileName = `image-${Date.now()}.${fileType}`;
        fileData.keyID = 0;
        fileData.fileContent = Array.from(fileBytes).join(",");
        fileData.fileTitle = "";
        fileData.fileDescription = isPostOnce ? "post-once" : "main";
        fileData.filesAddress = "";
        fileData.fileType = fileType;
        fileData.isImage = true;

        resolve(fileData);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  async function handleSingleImageUpload(file) {
    setMainImage(await setImageprops(file).then((res) => res));
  }

  function removeSliderImage(e, imageKeyID, isImage, isMain) {
    const headers = setHeaders();
    e.preventDefault();
    axios
      .delete(
        `${config.hostname}/api/v1/Gallery/${Idd}/GalleryFiles/${imageKeyID}`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: `عکس ${isMain ? "اصلی" : "اسلایدر"} حذف شد.`,
          });
          if (isMain) {
            refresh();
            setSliderImages((prevState) =>
              prevState.filter((img) => img.fileDescription !== "main")
            );
          } else {
            setSliderImages((prevState) =>
              prevState.filter((img) => img?.keyID !== imageKeyID)
            );
          }
        }
      });
  }

  return (
    <Modal className="gallery-files-modal" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>مدیریت عکس های گالری</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <SingleImageUploader
            removeSliderImage={removeSliderImage}
            onImageUpload={handleSingleImageUpload}
            activeImage={
              sliderImages?.filter((img) => img.fileDescription === "main")?.[0]
            }
            activeServiceID={Idd}
            title="عکس اصلی"
          />

          {sliderImages.filter((img) => img.fileDescription !== "main").length >
            0 && <p>عکس های اسلایدی آپلود شده</p>}
          <div className="slider-images-container">
            {sliderImages
              .filter((img) => img.fileDescription !== "main")
              .map((img) => (
                <div>
                  <img src={img.filesAddress} alt="slider" />
                  <button
                    className="delete-button"
                    onClick={(e) =>
                      removeSliderImage(e, img.keyID, img.isImage)
                    }
                  >
                    <TiDelete className="icon" />
                  </button>
                </div>
              ))}
          </div>

          <MultiImageUploader
            setImages={setNewSliderImages}
            images={newSliderImages}
          />

          {isUploading && (
            <div className="upload-percent-container">
              <div>
                <span>درصد اپلود عکس اصلی</span>
                <CircularProgressBar percentage={mainImageProgress} />
              </div>
              <div>
                <span>درصد اپلود عکس های چندتایی</span>
                <CircularProgressBar percentage={sliderImagesProgress} />
              </div>
            </div>
          )}
          <Button
            variant="contained"
            fullWidth
            onClick={uploadFiles}
            sx={{ marginTop: 2 }}
          >
            آپلود
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
