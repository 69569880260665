import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";

const MultiImageUploader = ({ images, setImages }) => {
  const [loading, setLoading] = useState(false);

  const convertToWebP = (file) =>
    new Promise((resolve) => {
      const fileData = {};
      const reader = new FileReader();
      reader.onload = async () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set canvas size to image size
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image on canvas
          ctx.drawImage(img, 0, 0);

          // Convert to WebP format
          canvas.toBlob(async (blob) => {
            const fileType = "webp";

            // Convert Blob to ArrayBuffer
            const arrayBuffer = await blob.arrayBuffer();
            const fileBytes = new Uint8Array(arrayBuffer);

            // Populate fileData
            fileData.fileName = `image-${Date.now()}.${fileType}`;
            fileData.keyID = 0;
            fileData.fileContent = Array.from(fileBytes).join(",");
            fileData.fileTitle = "";
            fileData.fileDescription = "slider";
            fileData.filesAddress = "";
            fileData.fileType = fileType;
            fileData.isImage = true;
            fileData.preview = URL.createObjectURL(blob);

            resolve(fileData);
          }, "image/webp");
        };
      };
      reader.readAsDataURL(file);
    });

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setLoading(true);

    const formattedImages = await Promise.all(
      files.map((file) => convertToWebP(file))
    );

    setImages((prev) => [...prev, ...formattedImages]);
    setLoading(false);
  };

  const handleRemoveImage = (fileName) => {
    setImages((prev) => prev.filter((img) => img.fileName !== fileName));
  };

  return (
    <div className="multi-image-uploader">
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        className="mb-4"
      />
      {loading && <p>در حال پردازش تصاویر...</p>}
      <div className="image-previews">
        {images.map((img) => (
          <div
            key={img.fileName}
            className="image-preview"
            style={{ position: "relative" }}
          >
            <img src={img.preview} alt={img.fileName} width="200" />
            <p>{img.fileName}</p>
            <button
              onClick={() => handleRemoveImage(img.fileName)}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <TiDelete className="icon" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiImageUploader;
