import React, { useEffect, useState } from "react";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import TimelineIcon from "@mui/icons-material/Timeline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import MessageIcon from "@mui/icons-material/Message";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ReportIcon from "@mui/icons-material/Report";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { NavLink, useLocation } from "react-router-dom";

import "./Sidebar.css";
import { parseJwt, setHeaders } from "../../utils";
import axios from "axios";
import config from "../../config";

function Sidebar() {
  const location = useLocation();
  const [isUserMainAdmin, setIsUserMainAdmin] = useState(false);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("user"));
    if (localStorageData) {
      const decodedUser = parseJwt(localStorageData.token);

      if (
        decodedUser[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ] === "superadmin"
      ) {
        setIsUserMainAdmin(true);
      } else {
        if (isUserMainAdmin) {
          setIsUserMainAdmin(false);
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("user"));
    if (!isUserMainAdmin && localStorageData) {
      getAdminPermissions();
    }
  }, [isUserMainAdmin]);

  function getAdminPermissions() {
    const headers = setHeaders();

    axios
      .get(`${config.hostname}/api/v1/Permission`, { headers })
      .then((res) => {
        console.log("permisisionsdafs", res.data);
        setPermissions(res.data);

        setAvailablePermissions(
          res.data
            .filter((permission) => permission.name.includes("مشاهده لیست"))
            .map((permission) => ({
              ...permission,
              name: permission.name.split("-")[0],
            }))
        );
      });
  }

  console.log("availablePermissions", availablePermissions);

  function renderNavItem(permissionName, path, label, IconComponent) {
    if (
      isUserMainAdmin ||
      availablePermissions.some(
        (permission) => permission.name === permissionName
      )
    ) {
      return (
        <li className="sidebarListItem">
          <NavLink to={path} className="link">
            <IconComponent />
            {label}
          </NavLink>
        </li>
      );
    }
    return null;
  }

  return (
    <>
      <div className="sidebar">
        <div className="sidbarWrapper">
          <div className="sidebarMenue">
            <h3 className="sidebarTitle">داشبورد</h3>
            <ul className="sidebarList">
              <li className="sidebarListItem">
                <NavLink to="/" className="link">
                  <LineStyleIcon />
                  خانه
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="sidebarMenue">
            <h3 className="sidebarTitle">منوی دسترسی</h3>
            <ul className="sidebarList">
              {isUserMainAdmin && (
                <li className="sidebarListItem">
                  <NavLink to="/users" className="link">
                    <PermIdentityIcon />
                    کاربران
                  </NavLink>
                </li>
              )}
              {renderNavItem(
                "پزشکان",
                "/physician",
                "پزشکان",
                PermIdentityIcon
              )}

              {renderNavItem(
                "پزشکان تایید نشده",
                "/unverifiedDoctors",
                "پزشکان تایید نشده",
                PermIdentityIcon
              )}

              {(isUserMainAdmin ||
                permissions.filter(
                  (permission) =>
                    permission.name.split("-")[1] === "مشاهده لیست بررسی"
                ).length > 0) && (
                <li className="sidebarListItem">
                  <NavLink to="/doctorsFiles" className="link">
                    <StorefrontIcon />
                    تمرین/آموزش پزشکان
                  </NavLink>
                </li>
              )}

              {renderNavItem(
                "بیماران",
                "/patient",
                "بیماران",
                PermIdentityIcon
              )}

              {renderNavItem(
                "تمرین ها",
                "/Exercise",
                "تمرینات",
                StorefrontIcon
              )}

              {renderNavItem(
                "آموزش ها",
                "/Education",
                "آموزش ها",
                StorefrontIcon
              )}

              {renderNavItem(
                "اشتراک ها",
                "/subscriptions",
                "اشتراک ها",
                StorefrontIcon
              )}

              {renderNavItem(
                "تیکت ها",
                "/tickets",
                "مدیریت پیام ها",
                StorefrontIcon
              )}

              {renderNavItem("تخفیف ها", "/offs", "تخفیف ها", StorefrontIcon)}

              {renderNavItem("سفارش ها", "/orders", "سفارش ها", StorefrontIcon)}

              {renderNavItem("گالری", "/gallery", "گالری", PhotoLibraryIcon)}

              {isUserMainAdmin && (
                <li className="sidebarListItem">
                  <NavLink to="/accesses" className="link">
                    <StorefrontIcon />
                    دسترسی ها
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
