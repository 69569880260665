import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { setHeaders } from "../../utils";
import PersianDatePicker from "../../Components/PersianDatePicker";
import Swal from "sweetalert2";

export default function EditModal({ Idd, isOpen, onClose, refresh }) {
  const initialGallery = {
    name: "",
    title: "",
    description: "",
    eventDate: "",
    isActive: true,
  };
  const [gallery, setGallery] = useState(initialGallery);

  useEffect(() => {
    if (Idd > 0) {
        console.log("idd", Idd)
      const headers = setHeaders();
      axios
        .get(`${config.hostname}/api/v1/Gallery/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setGallery(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setGallery(initialGallery);
    }
  }, [Idd]);

  async function updateGallery() {
    const headers = setHeaders();
    const newGallery = {
      ...gallery,
      keyID: Idd,
      viewIndex: 0,
    };

    axios
      .put(config.hostname + `/api/v1/Gallery`, newGallery, { headers })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "گالری با موفقیت ویرایش شد",
          });
          refresh();
          onClose();
        }
      })
      .catch((error) => console.error("خطا در درخواست به API: ", error));
  }

  async function insertGallery() {
    const headers = setHeaders();
    const newGallery = {
      ...gallery,
      keyID: 0,
      viewIndex: 0,
    };

    axios
      .post(config.hostname + `/api/v1/Gallery`, newGallery, { headers })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "گالری با موفقیت اضافه شد",
          });
          refresh();
          onClose();
        }
      })
      .catch((error) => console.log("err", error));
  }

  function closeModal() {
    setGallery(initialGallery);
    onClose();
  }

  function changeDate(formatedTime, propName) {
    const newDate = new Date(formatedTime);
    setGallery((prevState) => ({
      ...prevState,
      [propName]: newDate.toISOString(),
    }));
  }

  function changeStartDate(formatedTime) {
    changeDate(formatedTime, "eventDate");
  }

  return (
    <Modal className="offs-modal" open={isOpen} onClose={closeModal}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش گالری" : "ایجاد گالری"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-around",
            }}
          >
            <FormControl>
              <FormLabel>وضعیت</FormLabel>
              <RadioGroup
                row
                aria-label="isActive"
                name="isActive"
                value={gallery.isActive ? 1 : 0}
                onChange={(e) => {
                  setGallery((prevState) => ({
                    ...prevState,
                    isActive: !!+e.target.value,
                  }));
                }}
              >
                <FormControlLabel
                  style={{ marginRight: "0" }}
                  value={1}
                  control={<Radio />}
                  label="فعال"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="غیرفعال"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div style={{ display: "flex", gap: "1rem" }}>
            <TextField
              itemID="title"
              type="text"
              label="عنوان"
              value={gallery.title}
              onChange={(e) =>
                setGallery({ ...gallery, title: e.target.value })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />

            <TextField
              itemID="name"
              type="text"
              label="عنوان (به انگلیسی)"
              value={gallery.name}
              onChange={(e) => setGallery({ ...gallery, name: e.target.value })}
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
          </div>

          <TextField
            itemID="description"
            label="توضیحات"
            value={gallery.description}
            onChange={(e) =>
              setGallery({ ...gallery, description: e.target.value })
            }
            multiline
            rows={6}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: "10px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <FormControl>
              <FormLabel>تاریخ رویداد</FormLabel>

              <PersianDatePicker
                initialDate={gallery.eventDate ? gallery.eventDate : null}
                setTime={changeStartDate}
                isFree={true}
              />
            </FormControl>
          </div>

          <Button
            variant="contained"
            fullWidth
            onClick={Idd > 0 ? updateGallery : insertGallery}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش گالری" : "ایجاد گالری"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
