import React, { useEffect, useState, useContext } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { SelectedGroup } from "../../Contexts/SelectedGroup";
import axios from "axios";
import config from "../../config.js";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";
import { green, lightGreen, red, lightBlue } from "@mui/material/colors";
import { setHeaders } from "../../utils.js";

const ListView = (idd) => {
  const [items, setItems] = useState([]);
  const [itemsID, setItemsID] = useState([]);
  const [newItemText, setNewItemText] = useState("");
  const { selectedGroupsList } = useContext(SelectedGroup);
  const { selectedtgrp, updateExerSelectedGroupVariable } =
    useContext(SelectedGroup);
  const [treeKey, setTreeKey] = useState(0);

  const posteListeGroupe = (newItemsID) => {
    const newCategorise = {
      educationID: idd,
      ctgIDList: newItemsID,
    };
    try {
      const headers = setHeaders();

      axios.put(
        config.hostname + `/api/v1/Education/SetCategories`,
        {
          educationID: newCategorise.educationID.idd,
          ctgIDList: newCategorise.ctgIDList,
        },
        { headers }
      );
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const addItem = () => {
    const inputElement = document.querySelector('input[itemID="maingrpid"]');
    const inputElement2 = document.querySelector('input[itemID="editgroupid"]');
    const inputElement3_btnokid = document.querySelector(
      'button[itemID="btnokid"] span'
    );

    if (inputElement2.style.display == "inline") {
      SaveGroupName(inputElement2.value);
      updateExerSelectedGroupVariable({
        keyid: selectedtgrp.keyid,
        text: inputElement2.value,
      });
      inputElement.style.display = "inline";
      inputElement2.style.display = "none";
      inputElement2.value = "";
      inputElement3_btnokid.textContent = "افزودن به لیست";
    } else {
      if (selectedtgrp.keyid != -1) {
        if (!itemsID.includes(selectedtgrp.keyid)) {
          setItems((prevItems) => {
            const newItems = [...prevItems, selectedtgrp.text];
            //posteListeGroupe(newItems, itemsID);
            return newItems;
          });

          setItemsID((prevItemsID) => {
            const newItemsID = [...prevItemsID, selectedtgrp.keyid];
            posteListeGroupe(newItemsID);
            return newItemsID;
          });

          updateExerSelectedGroupVariable({
            keyid: 0,
            text: "",
          });
          setNewItemText("");
        }
      }
      //else
    }
  };

  const removeItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);

    setItemsID((prevItemsID) => {
      const newItemsID = [...prevItemsID];
      newItemsID.splice(index, 1);

      posteListeGroupe(newItemsID);
      return newItemsID;
    });

    posteListeGroupe(itemsID);
  };

  const refreshlistgroup = () => {
    const newexer = {
      educationID: idd,
    };
    const headers = setHeaders();

    axios
      .get(
        config.hostname +
          `/api/v1/EducationCtg/GetCategoriesByEduID?eduID=${newexer.educationID.idd}`,
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          const exercisesctgDataaa = res.data;

          const keyTEXTArray = exercisesctgDataaa.map((item) => item.ctgName);
          const keyIDsArray = exercisesctgDataaa.map((item) => item.keyID);

          setItemsID(keyIDsArray);
          setItems(keyTEXTArray);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  };

  useEffect(() => {
    setTreeKey((prevKey) => prevKey + 1);
    refreshlistgroup();
  }, [selectedGroupsList]);

  const SaveGroupName = (newgrpname) => {
    try {
      const headers = setHeaders();

      axios
        .put(
          config.hostname + `/api/v1/EducationCtg`,
          {
            keyID: selectedtgrp.keyid,
            parentKeyID: -1,
            categoryName: newgrpname,
          },
          { headers }
        )
        .catch((error) => {
          console.error("خطا در ویرایش : ", error);
        });
    } catch (error) {
      console.error("خطا در ویرایش : ", error);
    }
  };

  const handleedtigroup = () => {
    const inputElement = document.querySelector('input[itemID="maingrpid"]');
    const inputElement2 = document.querySelector('input[itemID="editgroupid"]');
    const inputElement3_btnokid = document.querySelector(
      'button[itemID="btnokid"] span'
    );

    if (inputElement2.style.display == "inline") {
      inputElement.style.display = "inline";
      inputElement2.style.display = "none";
      inputElement2.value = "";
      inputElement3_btnokid.textContent = "افزودن به لیست";
    } else {
      if (selectedtgrp.keyid > 0) {
        inputElement.style.display = "none";
        inputElement2.style.display = "inline";
        inputElement2.value = inputElement.value;
        inputElement3_btnokid.textContent = "ویرایش نام گروه";
      } else {
        if (
          window.confirm(
            "لطفا ابتدا از منوی درختی یک گروه را انتخاب نمایید سپس برای ویرایش نام گروه اقدام کنید"
          )
        ) {
          console.log("User clicked OK. Performing further actions...");
        } else {
          console.log("User clicked Cancel or closed the dialog.");
        }
      }
    }
  };

  const deletegroup = () => {
    const headers = setHeaders();
    axios
      .delete(config.hostname + `/api/v1/EducationCtg/${selectedtgrp.keyid}`, {
        headers,
      })
      .catch((error) => {
        console.error("خطا در درخواست به API برای حذف گروه: ", error);
      });
  };

  const handleDeletegroup = () => {
    if (selectedtgrp.keyid <= 0) {
      if (
        window.confirm(
          "لطفا ابتدا از منوی درختی یک گروه را انتخاب نمایید سپس برای حذف گروه اقدام کنید"
        )
      ) {
        console.log("User clicked OK. Performing further actions...");
      } else {
        console.log("User clicked Cancel or closed the dialog.");
      }
    } else {
      if (window.confirm("شما در حال حذف یک گروه هستید. آیا ادامه می دهید؟")) {
        deletegroup();
      } else {
      }
    }
  };
  const handleAddgroup = (subgroupid) => {
    const inputElement = document.querySelector('input[itemID="newgroupid"]');
    if (inputElement.value.trim() != "") {
      const ginfo = {
        keyID: -1,
        parentKeyID: subgroupid === -1 ? -1 : subgroupid,
        categoryName: inputElement.value,
      };
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const headers = setHeaders();
        axios
          .post(`${config.hostname}/api/v1/EducationCtg`, ginfo, { headers })
          .then((response) => {
            if (response.status === 200) {
              inputElement.value = "";
            }
          })
          .catch((error) => {
            console.error("Error sending data:", error);
          });
      }
    }
  };

  return (
    <div>
      <br></br>
      <input type="text" itemID="newgroupid" />
      <button onClick={() => handleAddgroup(-1)}>
        +افزودن گروه در شاخه اصلی
      </button>
      <button onClick={() => handleAddgroup(selectedtgrp.keyid)}>
        +افزودن زیر گروه
      </button>
      <br></br>
      <br></br>
      <label>گروه انتخاب شده:</label>
      <input type="text" value={selectedtgrp.text} itemID={"maingrpid"} />
      <input
        type="text"
        itemID="editgroupid"
        placeholder="نام جدید گروه وارد شود"
        style={{ display: "none", backgroundColor: "yellow" }}
      />
      <button onClick={addItem} itemID={"btnokid"}>
        <span>افزودن به لیست</span>
        <AddIcon style={{ backgroundColor: lightBlue, fontSize: "10px" }} />
      </button>
      <button onClick={handleedtigroup}>
        <EditIcon style={{ backgroundColor: lightGreen, fontSize: "10px" }} />
      </button>
      <button onClick={handleDeletegroup}>
        <DeleteIcon style={{ backgroundColor: red, fontSize: "10px" }} />
      </button>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item}
            <button onClick={() => removeItem(index)}>
              <RemoveIcon style={{ fontSize: "10px" }} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListView;
