import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Button } from "@mui/material";
import ModalPat from "./modal-pat";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import config from "../../config";
import Searchbox from "../../Components/Searchbox/Searchbox";
import { setHeaders } from "../../utils";
import usePagination from "../../hooks/usePagination";

function PatientList() {
  const [patientList, setpatientList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingpatientId, setEditingpatientId] = useState(null);

  const {
    page,
    pageSize,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    setTotalRows,
  } = usePagination(refreshlist);

  function handleEditClick(patientId) {
    setEditingpatientId(patientId);

    setEditModalOpen(true);
  }

  function handleafterEdit() {
    setEditModalOpen(false);
    refreshlist();
  }
  function refreshlist() {
    const headers = setHeaders();
    axios
      .get(`${config.hostname}/api/v1/Patient/${page},${pageSize}`, { headers })
      .then((res) => {
        if (res.status === 200) {
          const patientData = res.data.patientModelList;

          const formattedData = patientData.map((item) => ({
            id: item.keyID,
            fName: item.fName,
            lName: item.lName,
            nnAme: item.nnAme,
            medicalLicense: item.medicalLicense,
            nationalCode: item.nationalCode,
            birthDate: item.birthDate,
            gender: item.gender,
            mobile1: item.mobile1,
            mobile2: item.mobile2,
            email: item.email,
            description: item.description,
            speciallization: item.speciallization,
          }));
          setpatientList(formattedData);
          setTotalRows(res.data.totalcount);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function deletepatient(patientId) {
    const confirmDelete = window.confirm(
      "آیا مطمئن هستید که می‌خواهید این بیمار را حذف کنید؟"
    );

    if (confirmDelete) {
      const headers = setHeaders();
      axios
        .delete(config.hostname + `/api/v1/patient/${patientId}`, { headers })

        .catch((error) => {
          console.error("خطا در درخواست به API برای حذف بیمار: ", error);
        });
      refreshlist();
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "شناسه",
      width: 90,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() => handleEditClick(params.row.id)}
              >
                <EditIcon className="userListEdit" />
              </Link>
            }
            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => deletepatient(params.row.id)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "fName",
      headerName: "نام",
      width: 150,
    },
    {
      field: "lName",
      headerName: "نام خانوادگی",
      width: 150,
    },
    {
      field: "nnAme",
      headerName: "نام مستعار",
      width: 150,
    },
    {
      field: "nationalCode",
      headerName: "کد ملی",
      width: 150,
    },
    {
      field: "birthDate",
      headerName: "تاریخ تولد",
      width: 150,
      hide: true,
    },
    {
      field: "gender",
      headerName: "جنسیت",
      width: 120,
      hide: true,
    },
    {
      field: "mobile1",
      headerName: "موبایل 1",
      width: 150,
    },
    {
      field: "mobile2",
      headerName: "موبایل 2",
      width: 150,
      hide: true,
    },
    {
      field: "email",
      headerName: "ایمیل",
      width: 200,
    },
    {
      field: "description",
      headerName: "توضیحات",
      width: 150,
      hide: true,
    },
  ];

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="userListInsert" />
          </Button>
          <Searchbox />
        </div>
        <DataGrid
          rows={patientList}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          rowCount={totalRows}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          checkboxSelection
        />
      </div>

      <ModalPat
        isOpen={isEditModalOpen}
        onClose={() => handleafterEdit()}
        Idd={editingpatientId}
      />
    </>
  );
}

export default PatientList;
