import { Modal, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { setHeaders } from "../../utils";

export default function ExerModal({ Idd, isOpen, onClose, doctorID }) {
  const initialExercise = {
    name: "",
    preparation: "",
    execution: "",
  };
  const [exercise, setExercise] = useState(initialExercise);
  const [doctorFullName, setDoctorFullName] = useState("");
  const [files, setFiles] = useState([]);
  const [params, setParams] = useState([]);

  useEffect(() => {
    if (Idd) {
      const headers = setHeaders();
      axios
        .get(`${config.hostname}/api/v1/Exercise/${Idd}`, { headers })
        .then((res) => res.status === 200 && setExercise(res.data))
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      axios
        .get(
          `${config.hostname}/api/v1/Physician/Getbyguid?userid=${doctorID}`,
          {
            headers,
          }
        )
        .then(
          (res) =>
            res.status === 200 &&
            setDoctorFullName(`${res.data.fName} ${res.data.lName}`)
        )
        .catch((err) => console.log("err", err));

      axios
        .get(`${config.hostname}/api/v1/Exercise/${Idd}/ExerciseFiles`, {
          headers,
        })
        .then((res) => res.status === 200 && setFiles(res.data))
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      axios
        .get(`${config.hostname}/api/v1/Exercise/${Idd}/ExerciseParameters`, {
          headers,
        })
        .then((res) => res.status === 200 && setParams(res.data))
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [Idd]);

  function closeModal() {
    setExercise(initialExercise);
    setDoctorFullName("");
    setFiles([]);
    setParams([]);
    onClose();
  }

  return (
    <Modal
      className="offs-modal doctor-files-modal"
      open={isOpen}
      onClose={closeModal}
    >
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>جزییات تمرین</h2>
          <h4>ساخته شده توسط: {doctorFullName}</h4>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <div>
            <p>
              <span>عنوان تمرین: </span>
              {exercise.name}
            </p>
            <p>
              <span>نحوه اجرای تمرین: </span>
              {exercise.preparation}
            </p>
            <p>
              <span>نکات تمرین: </span>
              {exercise.execution}
            </p>
          </div>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <h4>پارامترهای تمرین</h4>

          <div className="exercise-parameters">
            {params?.map((parameter) => (
              <div key={parameter.keyID}>
                <span className="param-name">{parameter.paramName}</span>:{" "}
                <span>{parameter.paramValue}</span>
              </div>
            ))}
          </div>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <h4>فایل های تمرین</h4>
          <div className="files">
            {files.map((file) => (
              <div className="file">
                {file.isImage ? (
                  <img src={file.filesAddress} alt={file.fileName} />
                ) : (
                  <video controls>
                    <source
                      src={file.filesAddress}
                      type={`video/${file.fileType}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        </div>
      </Paper>
    </Modal>
  );
}
