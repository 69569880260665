import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePasswordRepeat,
  validatePhone,
  validateUsername,
} from "../../validation/validation";
import { setHeaders } from "../../utils";

function ModalUser({ isOpen, onClose, Idd }) {
  const [educationData, setEducationData] = useState({
    id: "",
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    passwordRepeat: "",
    email: "",
    phoneNumber: "",
    userType: "patient",
  });

  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordRepeatError, setPasswordRepeatError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [DoctorList2, setDoctorList2] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [password, setpassword] = useState(null);
  const [passwordRepeat, setpasswordRepeat] = useState(null);

  useEffect(() => {
    const {
      firstname,
      lastname,
      username,
      email,
      phoneNumber,
      password,
      passwordRepeat,
    } = educationData;

    setFirstnameError(validateName(firstname, "نام", 3));
    setLastnameError(validateName(lastname, "نام خانوادگی", 4));
    setUsernameError(validateUsername(username));
    setPasswordError(validatePassword(password, 8));
    setPasswordRepeatError(validatePasswordRepeat(passwordRepeat, password));
    setEmailError(validateEmail(email));
    setPhoneNumberError(validatePhone(phoneNumber));
  }, [educationData]);

  useEffect(() => {
    if (Idd) {
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/User/${Idd}`, { headers })
        .then((res) => {
          if (res.status === 200) {
            const { id, username, email, phoneNumber } = res.data;
            setEducationData({
              id,
              username,
              email,
              phoneNumber,
            });
            setpassword("");
            setpasswordRepeat("");
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setEducationData({
        id: "",
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        passwordRepeat: "",
        email: "",
        phoneNumber: "",
        userType: "patient",
      });
      const headers = setHeaders();
      axios
        .get(config.hostname + "/api/v1/Patient/1,1000", { headers })
        .then((res) => {
          if (res.status === 200) {
            const patients = res.data.patientModelList.map((patient) => ({
              id: patient.keyID,
              name: `${patient.fName} ${patient.lName}`,
            }));

            setPatientList(patients);
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });

      axios
        .get(config.hostname + "/api/v1/Physician/1,1000", { headers })
        .then((res) => {
          if (res.status === 200) {
            setDoctorList2(res.data);

            const doctors = res.data.physicianModelList.map((dr) => ({
              id: dr.keyID,
              name: `${dr.fName} ${dr.lName}`,
            }));
            setDoctorList(doctors);
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    }
  }, [Idd]);

  const updateUser = async () => {
    try {
      const headers = setHeaders();

      axios.put(
        config.hostname + `/api/v1/User/changePassword`,
        {
          userid: Idd,
          oldpass: "str",
          password: password,
          confirmPassword: passwordRepeat,
        },
        { headers }
      );

      onClose();
    } catch (error) {
      console.error("خطا در تغییر رمز کاربر: ", error);
    }
  };

  const [hasDoctorUsername, setHasDoctorUsername] = useState(false);

  async function Getusername(personKeyID, role) {

    try {
      const headers = setHeaders();
      if (role === "doctor") {
        axios
          .post(
            `${config.hostname}/api/v1/User/getusername`,
            {
              personKeyID,
              role: role === "doctor" ? "Admin" : "patient",
            },
            { headers }
          )
          .then((res) => {
            if (res.status === 200) {
              const username = res.data.usesrname;

              if (username) {
                setEducationData({ ...educationData, username });
                setHasDoctorUsername(true);
              } else {
                setHasDoctorUsername(false);
              }
            }
          });
      }
    } catch (error) {
      console.error("خطا در دریافت پزشک: ", error);
    }
  }

  const insertuser = async () => {
    const newUser = null;
    try {
      const headers = setHeaders();

      axios
        .post(
          `${config.hostname}/api/v1/User/Register`,
          {
            role: educationData.userType === "doctor" ? "admin" : "patient",
            userName: educationData.username,
            password: educationData.password,
            confirmPassword: educationData.passwordRepeat,
            personKeyID:
              educationData.userType === "doctor"
                ? educationData.doctorValue
                : educationData.patientValue,
            personFullName:
              educationData.userType === "doctor"
                ? doctorList.find(
                    (doctor) => doctor.id === educationData.doctorValue
                  )?.name
                : patientList.find(
                    (patient) => patient.id === educationData.patientValue
                  )?.name,
          },
          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            onClose();

            Swal.fire({
              title: "کاربر با موفقیت اضافه شد",
              icon: "success",
              confirmButtonText: "عالی",
            });
          }
        });
    } catch (error) {
      console.error("خطا در اضافه کردن پزشک: ", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd ? "تغییر رمز عبور" : "ایجاد کاربر"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <FormControl disabled={!!Idd}>
            <FormLabel>نوع کاربر</FormLabel>
            <RadioGroup
              row
              aria-label="userType"
              name="userType"
              value={educationData.userType}
              onChange={(e) => {
                setEducationData({
                  ...educationData,
                  userType: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="patient"
                control={<Radio />}
                label="بیمار"
              />
              <FormControlLabel
                value="doctor"
                control={<Radio />}
                label="پزشک"
              />
            </RadioGroup>
          </FormControl>

          <FormControl disabled={!!Idd} fullWidth style={{ marginTop: "10px" }}>
            <FormLabel>
              {educationData.userType === "doctor"
                ? "لیست پزشکان"
                : "لیست بیماران"}
            </FormLabel>
            <Select
              value={
                selectedId ||
                (educationData.userType === "doctor"
                  ? doctorList[0]?.id
                  : patientList[0]?.id)
              }
              onChange={(e) => {
                setSelectedId(e.target.value);
                setEducationData({
                  ...educationData,
                  doctorValue: e.target.value,
                  patientValue: e.target.value,
                  //username : `user${e.target.value*100}`,
                  username:
                    educationData.userType === "doctor"
                      ? ""
                      : `user${e.target.value * 100}`,
                });

                Getusername(e.target.value, educationData.userType);
              }}
              fullWidth
            >
              {educationData.userType === "doctor"
                ? doctorList.map((doctor, index) => (
                    <MenuItem key={index} value={doctor.id}>
                      {doctor.name}
                    </MenuItem>
                  ))
                : patientList.map((patient, index) => (
                    <MenuItem key={index} value={patient.id}>
                      {patient.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <TextField
            itemID="username"
            label="نام کاربری"
            value={educationData.username}
            onChange={(e) =>
              setEducationData({ ...educationData, username: e.target.value })
            }
            error={!!usernameError && educationData.userType !== "patient"}
            helperText={usernameError && usernameError}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            disabled={
              educationData.userType === "patient" || !!Idd || hasDoctorUsername
            }
          />

          <TextField
            itemID="phoneNumber"
            label="شماره تماس"
            value={educationData.phoneNumber}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                phoneNumber: e.target.value,
              })
            }
            error={!!phoneNumberError}
            helperText={phoneNumberError && phoneNumberError}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />
          <TextField
            itemID="email"
            label="ایمیل"
            value={educationData.email}
            onChange={(e) =>
              setEducationData({ ...educationData, email: e.target.value })
            }
            error={!!emailError}
            helperText={emailError && emailError}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />

          <TextField
            itemID="educationCategoryID"
            label="شناسه دسته‌بندی آموزش"
            value={educationData.educationCategoryID}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                educationCategoryID: e.target.value,
              })
            }
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
            dir="rtl"
          />
        </div>
        <div style={{ flex: 1, padding: "0 10px" }}>
          <br></br>
          <br></br> <br></br>
          <TextField
            itemID="password"
            label="رمزعبور"
            value={educationData.password}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                password: e.target.value,
              })
            }
            error={!!passwordError}
            helperText={passwordError && passwordError}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            disabled={hasDoctorUsername}
          />
          <TextField
            itemID="passwordRepeat"
            label="تکرار رمزعبور"
            value={educationData.passwordRepeat}
            onChange={(e) =>
              setEducationData({
                ...educationData,
                passwordRepeat: e.target.value,
              })
            }
            error={!!passwordRepeatError}
            helperText={passwordRepeatError && passwordRepeatError}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
            disabled={hasDoctorUsername}
          />
          <Button
            disabled={hasDoctorUsername}
            variant="contained"
            onClick={Idd ? updateUser : insertuser}
            sx={{ marginTop: 4 }}
          >
            {Idd ? "تغییر رمز عبور" : "ایجاد کاربر"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalUser;
