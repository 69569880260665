import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputLabel, Link, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import AuthContext from "../../Contexts/authContext";

import Swal from "sweetalert2";

import "./Login.css";
import config from "../../config";
import { isUserAdmin, parseJwt } from "../../utils";
import axios from "axios";

const defaultTheme = createTheme();

const styles = {
  blurredBackground: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  videoBackground: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
};

export default function SignInSide() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleRecaotchVerify, setIsGoogleRecaotchVerify] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsSubmitting(true);
    const userData = {
      userName: data.get("userName"),
      password: data.get("password"),
    };

    axios
      .post(config.hostname + "/api/v1/User/login", userData)
      .then((res) => {
        if (res.status !== 200) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        } else {
          setIsFormValid(true);
          const decodedUser = parseJwt(res.data[0]);

          const userRole =
            decodedUser[
              "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ];

          console.log("info", userRole);
          // if (userRole === "Admin" || userRole === "mainadmin") {
          if (userRole === "superadmin" || userRole === "mainadmin") {
            authContext.login({}, res.data[0]);
            Swal.fire({
              title: "با موفقیت لاگین شدید",
              icon: "success",
              confirmButtonText: "ورود به پنل",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
              } else {
                Swal.fire("تلاش دوباره", "درخواست تکراری", "error");
              }
            });
          } else {
            Swal.fire({
              title: "ادمینی با این مشخصات وجود ندارد",
              icon: "error",
              confirmButtonText: "تلاش دوباره",
            });
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "همچین کاربری وجود ندارد",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "تلاش دوباره",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <MuiRTL>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <video autoPlay loop muted style={styles.videoBackground}>
            <source src="./videos/istockphoto.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            className="blurredBackground"
          >
            <div className="login-form" style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    m: 1,
                    bgcolor: "#2f9bfa",
                    width: 100, // عرض مورد نظر
                    height: 100,
                    objectFit: "cover",
                  }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontFamily: "IRANSans", color: "#042b89" }}
                >
                  ورود به سیستم
                </Typography>
              </div>

              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  style={{ fontFamily: "IRANSans" }}
                  margin="normal"
                  required
                  fullWidth
                  id="userName"
                  label="نام کاربری"
                  name="userName"
                  autoFocus
                />

                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    رمز
                  </InputLabel>
                  <OutlinedInput
                    required
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  className="login-form-btn-successfuly"
                >
                  <p style={{ fontFamily: "IRANSans", color: "white" }}>ورود</p>
                </Button>

                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      <p style={{ fontFamily: "IRANSans" }}>
                        رمز خود را فراموش کردید ؟
                      </p>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      <p style={{ fontFamily: "IRANSans" }}>
                        {"اکانت ندارید؟ میخواید ثبت نام کنید؟"}
                      </p>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiRTL>
  );
}
