import { Modal, Paper } from "@mui/material";
import "./accessModal.css";
import { useEffect, useState } from "react";
import { parseJwt, setHeaders } from "../../utils";
import axios from "axios";
import config from "../../config";

export default function AccessModal({ Idd, isOpen, onClose }) {
  const [sectionsModel, setsectionsModel] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  
  useEffect(() => {
    getAllSections();
    getAllUserPermissions();
  }, []);

  useEffect(() => {
    console.log("user permissions", userPermissions);
  }, [userPermissions]);

  function getAllUserPermissions() {
    const headers = setHeaders();
    axios
      .get(`${config.hostname}/api/v1/Permission/UserID?UserID=${Idd}`, {
        headers,
      })
      .then((res) => {
        res.status === 200 && setUserPermissions(res.data);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function getAllSections() {
    const headers = setHeaders();
    axios
      .get(config.hostname + "/api/v1/Section", { headers })
      .then((res) => {
        res.status === 200 && setsectionsModel(res.data);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function changeAction(e, isSingle, actions, section) {
    const headers = setHeaders();
    const localStorageData = JSON.parse(localStorage.getItem("user"));
    const userLoggedIn = parseJwt(localStorageData.token);

    if (isSingle) {
      axios
        .post(
          config.hostname + "/api/v1/Permission",
          {
            userID: Idd,
            actionID: actions,
            isGranted: e.target.checked,
          },
          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            if (!e.target.checked) {
              axios
                .post(
                  `${config.hostname}/api/v1/Permission`,
                  {
                    userID: Idd,
                    actionID: section.keyID,
                    isGranted: true,
                  },
                  { headers }
                )
                .then((res) => res.status === 200 && getAllUserPermissions());
            } else {
              getAllUserPermissions();
            }
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      actions.forEach((action, index) =>
        axios
          .post(
            config.hostname + "/api/v1/Permission",
            {
              userID: Idd,
              actionID: action.keyID,
              isGranted: e.target.checked,
            },
            { headers }
          )
          .then((response) => {
            response.status === 200 && index === 1 && getAllUserPermissions();
          })
          .catch((error) => {
            console.error("خطا در درخواست به API: ", error);
          })
      );
    }

    // setsectionsModel((prevState) =>
    //   prevState.map((section) =>
    //     section.KeyID === sectionID ? { ...section } : section
    //   )
    // );
  }

  return (
    <Modal className="access-modal" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>تعیین دسترسی</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          {userPermissions && sectionsModel && (
            <div className="accesses">
              {sectionsModel.map((section) => (
                <div key={section.keyID} className="single-access-section">
                  <div className="access-action">
                    <input
                      type="checkbox"
                      id={section.name}
                      checked={
                        userPermissions.filter((permission) =>
                          [
                            `${section.name}-مشاهده لیست`,
                            `${section.name}-مشاهده`,
                          ].includes(permission.name)
                        ).length === 2
                      }
                      onChange={(e) =>
                        changeAction(
                          e,
                          false,
                          section.actions.filter(
                            (action) =>
                              action.name === "مشاهده لیست" ||
                              action.name === "مشاهده"
                          )
                        )
                      }
                    />
                    <label htmlFor={section.name}>{section.name}</label>
                  </div>
                  {section.actions.map((action) => (
                    <div key={action.keyID} className="access-action">
                      <input
                        type="checkbox"
                        name={action.name}
                        id={`${section.name}-${action.name}`}
                        checked={
                          userPermissions.filter(
                            (permission) =>
                              permission.name ===
                              `${section.name}-${action.name}`
                          ).length === 1
                        }
                        onChange={(e) =>
                          changeAction(
                            e,
                            true,
                            action.keyID,
                            section.actions.find(
                              (action) => action.name === "مشاهده لیست"
                            )
                          )
                        }
                      />
                      <label htmlFor={`${section.name}-${action.name}`}>
                        {action.name}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </Paper>
    </Modal>
  );
}
