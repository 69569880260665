import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import { FormControl, MenuItem, Select } from "@mui/material";
import config from "../../config";
import { setHeaders } from "../../utils";
import Swal from "sweetalert2";
import usePagination from "../../hooks/usePagination";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExerModal from "./ExerModal";
import EduModal from "./EduModal";

import "./DoctorsFiles.css";

function DoctorsFiles() {
  const [filesList, setFilesList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingFileID, setEditingFileID] = useState(null);
  const [doctorID, setDoctorID] = useState(null);
  const [fileType, setFileType] = useState("exer");

  const exerColumns = [
    {
      field: "keyID",
      headerName: "شناسه ",
      width: 70,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 80,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() =>
                  handleEditClick(params.row.keyID, params.row.createByPhyGuid)
                }
              >
                <VisibilityIcon className="userListEdit" />
              </Link>
            }

            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => removeFile(params.row.keyID)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "mainPicturePath",
      headerName: "عکس اصلی",
      width: 150,
      renderCell: (params) => (
        <img
          className="main-img"
          src={params.row.mainPicturePath || "/images/default.png"}
          alt="main"
        />
      ),
    },
    {
      field: "name",
      headerName: "عنوان",
      width: 150,
    },

    {
      field: "preparation",
      headerName: "مراحل آماده سازی",
      width: 150,
    },
  ];

  const eduColumns = [
    {
      field: "keyID",
      headerName: "شناسه ",
      width: 70,
    },
    {
      field: "action",
      headerName: "عملیات",
      width: 80,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginTop: "1px" }}>عملیات</span>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {
              <Link
                className="link"
                onClick={() =>
                  handleEditClick(params.row.keyID, params.row.createByPhyGuid)
                }
              >
                <VisibilityIcon className="userListEdit" />
              </Link>
            }

            {
              <Link className="link">
                <DeleteIcon
                  className="userListTrash"
                  onClick={() => removeFile(params.row.keyID)}
                />
              </Link>
            }
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "عنوان",
      width: 150,
    },

    {
      field: "content",
      headerName: "توضیحات",
      width: 150,
    },
  ];

  const {
    page,
    pageSize,
    totalRows,
    handlePageChange,
    handlePageSizeChange,
    setTotalRows,
  } = usePagination(getAllFiles);

  useEffect(() => {
    getAllFiles();
  }, [fileType]);

  function handleEditClick(fileID, doctorID) {
    setEditingFileID(fileID);
    setDoctorID(doctorID);
    setEditModalOpen(true);
  }

  function getAllFiles() {
    const headers = setHeaders();
    setFilesList([]);
    axios
      .get(
        `${config.hostname}/api/v1/${
          fileType === "exer"
            ? "Exercise/GetPhysicanExercise"
            : "Education/GetPhysicanEducation"
        }/${page},${pageSize}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("res data", res.data);
          setFilesList(
            fileType === "exer"
              ? res.data.exerciseslist
              : res.data.educationlist
          );
          setTotalRows(res.data.totalcount);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function removeFile(fileID) {
    Swal.fire({
      icon: "question",
      title: `آیا از حذف ${
        fileType === "exer" ? "تمرین" : "آموزش"
      } مطمئن هستید؟`,
      confirmButtonText: "بله",
      showCancelButton: true,
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .delete(
            `${config.hostname}/api/v1/${
              fileType === "exer" ? "exercise" : "education"
            }/${fileID}`,
            { headers }
          )
          .then((res) => {
            if (res.status === 200) {
              getAllFiles();
              Swal.fire({
                icon: "success",
                title: `${
                  fileType === "exer" ? "تمرین" : "آموزش"
                } با موفقیت حذف شد`,
              });
            }
          })
          .catch((error) => {
            console.error("خطا در درخواست به API برای حذف: ", error);
          });
      }
    });
  }

  function closeModal() {
    setEditModalOpen(false);
    setDoctorID(null);
    setEditingFileID(null);
  }

  return (
    <>
      <div sx={{ height: 520, width: "90%" }} className="userList">
        <div className="education-actions">
          <FormControl
            fullWidth
            style={{ marginTop: "10px", width: "fit-content" }}
          >
            <Select
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              fullWidth
            >
              <MenuItem value="exer">تمرین</MenuItem>
              <MenuItem value="edu">آموزش</MenuItem>
            </Select>
          </FormControl>
        </div>
        {filesList.length > 0 && (
          <DataGrid
            rows={filesList}
            getRowId={(row) => row.keyID}
            columns={fileType === "exer" ? exerColumns : eduColumns}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            rowCount={totalRows}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            disableSelectionOnClick
            checkboxSelection
          />
        )}
      </div>

      {isEditModalOpen &&
        (fileType === "exer" ? (
          <ExerModal
            Idd={editingFileID}
            doctorID={doctorID}
            isOpen={isEditModalOpen}
            onClose={closeModal}
          />
        ) : (
          <EduModal
            Idd={editingFileID}
            doctorID={doctorID}
            isOpen={isEditModalOpen}
            onClose={closeModal}
          />
        ))}
    </>
  );
}

export default DoctorsFiles;
