import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import config from "../../config";
import { setHeaders } from "../../utils";

function ModalUser({ isOpen, onClose, Idd }) {
  const [fName, setfName] = useState(null);
  const [lName, setlName] = useState(null);
  const [nnAme, setnnAme] = useState(null);
  const [nationalCode, setnationalCode] = useState(null);
  const [birthDate, setbirthDate] = useState(null);
  const [gender, setgender] = useState(null);
  const [mobile1, setmobile1] = useState(null);
  const [mobile2, setmobile2] = useState(null);
  const [email, setemail] = useState(null);
  const [description, setdescription] = useState(null);
  const [tell, setTell] = useState();
  const [address, setAddress] = useState(null);
  const [fax, setFax] = useState(null);
  const [cityid, setCityId] = useState(null);
  const [postalCode, setpostalCode] = useState(null);
  const [male, setMale] = useState(null);
  const [female, setFemale] = useState(null);
  const famousCities = ["رشت", "زاهدان", "تهران", "مشهد", "تبریز"];
  useEffect(() => {
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/Patient/${Idd}`, { headers })
        .then((res) => {
          if (res.status === 200) {
            const PatientData = res.data;
            setfName(PatientData.fName);
            setlName(PatientData.lName);
            setnnAme(PatientData.nnAme);
            setnationalCode(PatientData.nationalCode);
            setbirthDate(PatientData.birthDate);
            setgender(PatientData.gender);
            setmobile1(PatientData.mobile1);
            setmobile2(PatientData.mobile2);
            setemail(PatientData.email);
            setdescription(PatientData.description);
            setTell(PatientData.tell);
            setAddress(PatientData.address);
            setFax(PatientData.fax);
            setCityId(famousCities[PatientData.cityId - 1]);
            setpostalCode(PatientData.postalCode);
            if (PatientData.gender == 1) {
              setFemale(true);
              setMale(false);
            } else {
              setFemale(false);
              setMale(true);
            }
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setfName("");
      setlName("");
      setnnAme("");
      setnationalCode("");
      setbirthDate("");
      setgender("");
      setmobile1("");
      setmobile2("");
      setemail("");
      setdescription("");
      setTell("");
      setAddress("");
      setFax("");
      setCityId("");
      setpostalCode("");
    }
  }, [Idd]);

  const updateUser = async () => {
    try {
      const headers = setHeaders();
      const response = await axios.put(
        config.hostname + `/api/v1/Patient`,
        {
          keyID: Idd,
          fName,
          lName,
          nnAme,
          nationalCode,
          birthDate,
          gender: female ? 1 : 2,

          mobile1,
          mobile2,
          email,
          description,
          tell,
          fax,
          postalCode,
          cityid: famousCities.indexOf(cityid) + 1,
          address,
        },
        { headers }
      );

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const insertuser = async () => {
    try {
      const headers = setHeaders();

      const response = await axios.post(
        config.hostname + `/api/v1/Patient`,
        {
          keyID: -1,
          fName: fName,
          lName: lName,
          nnAme: nnAme,
          nationalCode: nationalCode,
          birthDate: "2023-11-02T06:57:07.150Z",
          gender: 0,
          mobile1: mobile1,
          mobile2: mobile2,
          email: email,
          description: description,
          cityid: 1,
          postalCode: postalCode,
          tell: tell,
          fax: fax,
          address: address,
        },
        { headers }
      );

      onClose();
    } catch (error) {
      const confirmDelete4 = window.confirm(error);

      if (confirmDelete4) {
      }
      console.error("خطا در ایجاد : ", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش بیمار" : "ایجاد بیمار"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <TextField
            itemID="fName"
            label="نام"
            value={fName}
            onChange={(e) => setfName(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="lName"
            label="نام خانوادگی"
            value={lName}
            onChange={(e) => setlName(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="nName"
            label=" نام کاربری"
            value={nnAme}
            onChange={(e) => setnnAme(e.target.value)}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
          />

          <TextField
            itemID="nationalCode"
            label="کد ملی"
            value={nationalCode}
            onChange={(e) => setnationalCode(e.target.value)}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
          />

          <TextField
            itemID="birthDate"
            label="تاریخ تولد"
            value={birthDate}
            onChange={(e) => setbirthDate(e.target.value)}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
          />
          <FormControl>
            <FormLabel>جنسیت</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="gender"
              value={male ? "male" : female ? "female" : ""}
              onChange={(e) => {
                if (e.target.value === "male") {
                  setMale(true);
                  setFemale(false);
                } else if (e.target.value === "female") {
                  setMale(false);
                  setFemale(true);
                } else {
                  setMale(false);
                  setFemale(false);
                }
              }}
            >
              <FormControlLabel value="male" control={<Radio />} label="مرد" />
              <FormControlLabel value="female" control={<Radio />} label="زن" />
            </RadioGroup>
          </FormControl>

          <TextField
            itemID="mobile1"
            label="موبایل 1"
            value={mobile1}
            onChange={(e) => setmobile1(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="mobile2"
            label="موبایل 2"
            value={mobile2}
            onChange={(e) => setmobile2(e.target.value)}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
          />
        </div>
        <div style={{ flex: 1, padding: "0 10px" }}>
          <TextField
            itemID="email"
            label="ایمیل"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            fullWidth
            style={{ marginTop: "65px" }}
          />

          <TextField
            itemID="description"
            label="توضیحات"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />

          <TextField
            itemID="tell"
            label="تلفن"
            value={tell}
            onChange={(e) => setTell(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="address"
            label="آدرس"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
          <TextField
            itemID="fax"
            label="فکس"
            value={fax}
            onChange={(e) => setFax(e.target.value)}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
          />
          <TextField
            itemID="postalCode"
            label="کد پستی"
            value={postalCode}
            onChange={(e) => setpostalCode(e.target.value)}
            fullWidth
            style={{ marginTop: "10px", display: "none" }}
          />
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <FormLabel>شهر</FormLabel>
            <Select
              value={cityid}
              onChange={(e) => setCityId(e.target.value)}
              fullWidth
            >
              {famousCities.map((city, index) => (
                <MenuItem key={index} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={Idd > 0 ? updateUser : insertuser}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش بیمار" : "ایجاد بیمار"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalUser;
