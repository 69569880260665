import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { setHeaders } from "../../utils";

export default function EditModal({
  Idd,
  isOpen,
  onClose,
  formatDays,
  refresh,
}) {
  const initialSubscription = {
    days: 0,
    price: 0,
    discount: 0,
    exerciseLimit: 0,
    educationLimit: 0,
    exerFileLimit: 0,
    eduFileLimit: 0,
    isActive: 1,
    description: "",
  };
  const [subscription, setSubscription] = useState(initialSubscription);
  const [allDurations, setAllDurations] = useState([
    {
      id: 1,
      title: "نامحدود",
      value: 0,
    },
    {
      id: 2,
      title: "دو هفته‌ای",
      value: 14,
    },
    {
      id: 3,
      title: "1 ماهه",
      value: 30,
    },
    {
      id: 4,
      title: "2 ماهه",
      value: 60,
    },
    {
      id: 5,
      title: "3 ماهه",
      value: 90,
    },
    {
      id: 6,
      title: "6 ماهه",
      value: 180,
    },
    {
      id: 7,
      title: "1 ساله",
      value: 365,
    },
    {
      id: 8,
      title: "2 ساله",
      value: 365 * 2,
    },
    {
      id: 9,
      title: "3 ساله",
      value: 365 * 3,
    },
    {
      id: 10,
      title: "12 ساله",
      value: 365 * 12,
    },
  ]);

  useEffect(() => {
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(config.hostname + `/api/v1/Service/${Idd}`, { headers })
        .then((res) => {
          if (res.status === 200) {
            setSubscription({
              ...res.data,
              isActive: res.data.isActive ? 1 : 0,
            });
          }
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setSubscription(initialSubscription);
    }
  }, [Idd]);

  async function updateSubscription() {
    try {
      const headers = setHeaders();
      const newSubscription = {
        ...subscription,
        subject: `اشتراک ${
          subscription.days ? formatDays(subscription.days) + "ه" : "نامحدود"
        }`,
        keyID: Idd,
        isActive: !!subscription.isActive,
        discout: subscription.discount,
      };

      axios
        .put(config.hostname + `/api/v1/Service`, newSubscription, { headers })
        .then((res) => {
          refresh();
          onClose();
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  }

  async function insertSubscription() {
    try {
      const headers = setHeaders();
      const newSubscription = {
        ...subscription,
        subject: `اشتراک ${
          subscription.days ? formatDays(subscription.days) + "ه" : "نامحدود"
        }`,
        keyID: Idd,
        isActive: !!subscription.isActive,
        discout: subscription.discount,
      };

      axios
        .post(config.hostname + `/api/v1/Service`, newSubscription, { headers })
        .then((res) => {
          refresh();
          onClose();
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  }

  function closeModal() {
    setSubscription(initialSubscription);
    onClose();
  }

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش اشتراک" : "ایجاد اشتراک"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />
          <div style={{ display: "flex", gap: "1rem" }}>
            <FormControl fullWidth>
              <FormLabel>وضعیت</FormLabel>
              <RadioGroup
                row
                aria-label="isActive"
                name="isActive"
                value={subscription.isActive}
                onChange={(e) => {
                  setSubscription((prevState) => ({
                    ...prevState,
                    isActive: +e.target.value,
                  }));
                }}
              >
                <FormControlLabel
                  style={{ marginRight: "0" }}
                  value={1}
                  control={<Radio />}
                  label="فعال"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="غیرفعال"
                />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <FormLabel>مدت زمان اشتراک</FormLabel>
              <Select
                value={subscription.days}
                onChange={(e) =>
                  setSubscription({ ...subscription, days: e.target.value })
                }
                fullWidth
              >
                {allDurations.map((duration, index) => (
                  <MenuItem key={index} value={duration.value}>
                    {duration.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <TextField
              itemID="subscriptionPrice"
              type="number"
              label="قیمت اشتراک"
              value={subscription.price}
              onChange={(e) =>
                setSubscription({ ...subscription, price: +e.target.value })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />

            <TextField
              itemID="subscriptionPrice"
              type="number"
              label="تخفیف اشتراک (%)"
              value={subscription.discount}
              onChange={(e) =>
                setSubscription({ ...subscription, discount: +e.target.value })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
          </div>

          <div style={{ display: "flex", gap: "1rem" }}>
            <TextField
              itemID="subscriptionPrice"
              type="number"
              label="تعداد تمرین قابل ایجاد"
              value={subscription.exerciseLimit}
              onChange={(e) =>
                setSubscription({
                  ...subscription,
                  exerciseLimit: +e.target.value,
                })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
            <TextField
              itemID="subscriptionPrice"
              type="number"
              label="تعداد عکس و فیلم قابل آپلود"
              value={subscription.exerFileLimit}
              onChange={(e) =>
                setSubscription({
                  ...subscription,
                  exerFileLimit: +e.target.value,
                })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <TextField
              itemID="subscriptionPrice"
              type="number"
              label="تعداد آموزش قابل ایجاد"
              value={subscription.educationLimit}
              onChange={(e) =>
                setSubscription({
                  ...subscription,
                  educationLimit: +e.target.value,
                })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
            <TextField
              itemID="subscriptionPrice"
              type="number"
              label="تعداد فایل قابل آپلود"
              value={subscription.eduFileLimit}
              onChange={(e) =>
                setSubscription({
                  ...subscription,
                  eduFileLimit: +e.target.value,
                })
              }
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              style={{ marginTop: "2rem" }}
              dir="rtl"
            />
          </div>

          <TextField
            itemID="exercisesPreparation"
            label="توضیحات"
            value={subscription.description}
            onChange={(e) =>
              setSubscription({ ...subscription, description: e.target.value })
            }
            multiline
            rows={6}
            fullWidth
            style={{ marginTop: "10px" }}
            dir="rtl"
          />

          <Button
            variant="contained"
            fullWidth
            onClick={Idd > 0 ? updateSubscription : insertSubscription}
            sx={{ marginTop: 2 }}
          >
            {Idd > 0 ? "ویرایش اشتراک" : "ایجاد اشتراک"}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
